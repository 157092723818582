import React, { FC } from 'react'

import {
  Card,
  Stack,
  Text,
  Grid,
  Column,
  HorizontalLine,
  ExternalLink,
  LinkText,
  Breakpoint,
} from '@components'

export const HomeScreenFeaturesCard: FC = () => {
  return (
    <Card variant='homeScreen'>
      <Stack gap={10}>
        <Breakpoint display={0}>
          <GuildsAndNetworks />

          <Events />

          <Presentations />
        </Breakpoint>
        <Breakpoint display={[1, 2, 3, 4]}>
          <Grid gap={6}>
            <Column>
              <GuildsAndNetworks />
            </Column>

            <Column>
              <Events />
            </Column>

            <Column>
              <Presentations />
            </Column>
          </Grid>
        </Breakpoint>

        <Stack gap={0} alignItems='center'>
          <HorizontalLine variant='homeScreenFeatures' />
        </Stack>

        <Stack gap={0} alignItems='center'>
          <Text size={4} variant='caption'>
            Not sure about a feature?
          </Text>

          <Text size={4} variant='caption' textAlign='center'>
            <ExternalLink to='mailto:hi@guild.host'>
              <LinkText size={4} variant='caption'>
                Get in touch
              </LinkText>
            </ExternalLink>
            , we'd love to chat with you!
          </Text>
        </Stack>
      </Stack>
    </Card>
  )
}

const GuildsAndNetworks: FC = () => {
  return (
    <Stack gap={4}>
      <Text size={6} variant='homeGuildsHeading'>
        Guilds & Networks
      </Text>

      <Stack gap={2}>
        {[
          'Create a Following',
          'Manage your Members',
          '3-tier Permissions System',
          'Inter-connect with Networks',
          'Cross-promote via Networks',
          'Accept Donations',
          'Notify your Slack',
          'Announce on Bluesky',
          'Embeddable Widgets',
          'API Access',
          'Mirror your Meetup.com events',
        ].map((bullet) => (
          <Stack gap={2} direction='horizontal' key={bullet}>
            <Text size={4} variant='homeGuildsCheckmark'>
              ✓
            </Text>

            <Text size={4}>{bullet}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

const Events: FC = () => {
  return (
    <Stack gap={4}>
      <Text size={6} variant='homeEventsHeading'>
        Events
      </Text>

      <Stack gap={2}>
        {[
          'In-Person Events',
          'Online Events',
          'Hybrid In-Person & Online Events',
          'Optional Waitlist',
          'Guild Events',
          'One-Off Personal Events',
          'Connect Presentations',
          'Embeddable Widgets',
        ].map((bullet) => (
          <Stack gap={2} direction='horizontal' key={bullet}>
            <Text size={4} variant='homeEventsCheckmark'>
              ✓
            </Text>

            <Text size={4}>{bullet}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

const Presentations: FC = () => {
  return (
    <Stack gap={4}>
      <Text size={6} variant='homePresentationsHeading'>
        Presentations
      </Text>

      <Stack gap={2}>
        {[
          "Manage your Guild's Content Library",
          'Manage your Personal Content Library',
          'Connect Presentations to Events',
          'Connect Presentations to Guilds',
          'Curate Content from top Presenters',
          'Share Content across Networks',
          'Embeddable Widgets',
        ].map((bullet) => (
          <Stack gap={2} direction='horizontal' key={bullet}>
            <Text size={4} variant='homePresentationsCheckmark'>
              ✓
            </Text>

            <Text size={4}>{bullet}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
