import { useCallback } from 'react'

import { useMutation as useRelayMutation, UseMutationConfig } from 'react-relay'

import {
  GraphQLTaggedNode,
  MutationParameters,
  PayloadError,
} from 'relay-runtime'

import { addBreadcrumb, captureException } from '@sentry/react'

export const useMutation = <TMutation extends MutationParameters>(
  mutationGraphQL: GraphQLTaggedNode
) => {
  const [mutation] = useRelayMutation<TMutation>(mutationGraphQL)

  return useCallback(
    (config: UseMutationConfig<TMutation>) =>
      new Promise<TMutation['response']>((resolve, reject) => {
        mutation({
          ...config,
          onCompleted: (response, errors) => {
            try {
              if (config.onCompleted) {
                config.onCompleted(response, errors)
              }
            } finally {
              if (errors) {
                reject(new GraphQLError(errors))

                addBreadcrumb({
                  category: 'graphql.error',
                  message: JSON.stringify(errors),
                  level: 'debug',
                })
              } else {
                resolve(response)
              }
            }
          },
          onError: (error) => {
            try {
              if (config.onError) {
                config.onError(error)
              }
            } finally {
              reject(error)

              captureException(error)
            }
          },
        })
      }),
    [mutation]
  )
}

export class GraphQLError extends Error {
  constructor(errors: PayloadError[]) {
    const message = JSON.stringify(errors)

    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GraphQLError)
    }

    this.name = 'GraphQLError'
    this.message = message
    this.errors = errors
  }

  errors: PayloadError[]
}
