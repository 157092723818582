import React, { FC, useMemo } from 'react'
import { Element } from './Element'
import { Stack } from './Stack'

interface CardProps {
  variant?: string
  gap?: number
  width?: number
  expand?: boolean
  children: React.ReactNode
}

export const Card = ({
  children,
  variant,
  gap,
  width,
  expand = false,
}: CardProps) => {
  return (
    <Stack expand={expand}>
      <Element
        component='Card'
        variant={variant}
        tokens={width ? { width } : undefined}
        style={expand ? { flex: 1 } : undefined}
      >
        <Stack gap={gap} expand={expand}>
          {children}
        </Stack>
      </Element>
    </Stack>
  )
}
