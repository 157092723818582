import React, { FC, useEffect, useRef, useState } from 'react'
import { View, PixelRatio, LayoutRectangle } from 'react-native'

import { interpolate, Extrapolate } from 'react-native-reanimated'

import { svgPathProperties } from 'svg-path-properties'

import { ErrorBoundary } from 'react-error-boundary'

import {
  Breakpoint,
  Card,
  Column,
  Grid,
  Stack,
  Text,
  useBreakpoint,
  Icon,
  Image,
  Link,
} from '@components'

import { selectBreakpointToken } from '@components/util/selectBreakpointToken'

import guildsNetworksVisualFull from '../../../assets/home-page/guilds-networks-visual-full.svg'

import singleNetwork from '../../../assets/home-page/single-network.svg'
import singleGuild from '../../../assets/home-page/single-guild.svg'

import reactAdvancedSvg from '../../../assets/home-page/guild-icon-bw-react-advanced.svg'
import reactAmsterdamSvg from '../../../assets/home-page/guild-icon-bw-react-amsterdam.svg'
import torontojsSvg from '../../../assets/home-page/guild-icon-torontojs.svg'
import svelteSocietySvg from '../../../assets/home-page/guild-icon-svelte-society.svg'
import gitpodIcon from '../../../assets/home-page/guild-icon-gitpod-london.svg'

const ICON_CONTENT = {
  network1: { type: 'icon', name: 'heart' },
  network2: { type: 'icon', name: 'globe' },
  network3: { type: 'icon', name: 'star' },
  guild1: {
    type: 'image',
    src: reactAdvancedSvg,
    link: '/react-advanced-london',
  },
  guild2: { type: 'image', src: reactAmsterdamSvg, link: '/react-amsterdam' },
  guild3: { type: 'image', src: gitpodIcon, link: '/gitpod-london' },
  guild4: {
    type: 'image',
    src: svelteSocietySvg,
    link: '/svelte-society-london',
  },
  guild5: { type: 'image', src: torontojsSvg, link: '/torontojs' },
} as const

export const HomeScreenGuildNetworksCard: FC = () => {
  return (
    <Card variant='homeScreenGuildContent'>
      <Breakpoint display={[0, 1, 2]}>
        <Stack gap={8}>
          <Stack gap={2}>
            <Stack gap={1} direction='horizontal' alignItems='center'>
              <Image source={{ uri: singleNetwork }} size={8} />

              <Text variant='heroLight' size={7}>
                Networks
              </Text>
            </Stack>

            <Text size={4} variant='headingLight'>
              Communities don't exist in isolation.
            </Text>

            <Grid>
              <Column span={[1, 1, 5, 1]}>
                <Text size={4} variant='onSurface'>
                  Whether it's{' '}
                  <Icon
                    name='heart-filled'
                    variant='networkVisualText'
                    size={3.5}
                  />{' '}
                  interest-based,{' '}
                  <Icon
                    name='globe-filled'
                    variant='networkVisualText'
                    size={3.5}
                  />{' '}
                  geographically, or by{' '}
                  <Icon
                    name='star-filled'
                    variant='networkVisualText'
                    size={3.5}
                  />{' '}
                  brand, your community can accurately represent its
                  intersectionality via Networks enabling cross-promotion and
                  content sharing.
                </Text>
              </Column>

              <Column span={[0, 0, 1, 0]} />
            </Grid>
          </Stack>

          <Stack alignItems='center' variant='fullWidth'>
            <ErrorBoundary
              fallback={
                <Image
                  source={{ uri: guildsNetworksVisualFull }}
                  style={{
                    flex: 1,
                    aspectRatio: 538 / 324,
                    width: '100%',
                    maxWidth: 600,
                  }}
                  resizeMode='contain'
                />
              }
            >
              <NetworkVisual />
            </ErrorBoundary>
          </Stack>

          <Stack gap={2}>
            <Stack gap={1} direction='horizontal' alignItems='center'>
              <Image source={{ uri: singleGuild }} size={8} />

              <Text variant='heroLight' size={7}>
                Guilds
              </Text>
            </Stack>

            <Text size={4} variant='headingLight'>
              The home for your community.
            </Text>

            <Grid>
              <Column span={[1, 1, 5, 1]}>
                <Text size={4} variant='onSurface'>
                  Every Guild can host its own Events and create a portfolio of
                  Presentations. Keep your members notified and engaged in the
                  fun!
                </Text>
              </Column>

              <Column span={[0, 0, 1, 0]} />
            </Grid>
          </Stack>
        </Stack>
      </Breakpoint>

      <Breakpoint display={[3, 4]}>
        <Grid gap={6}>
          <Column span={1}>
            <Stack gap={18} justifyContent='center' expand>
              <Stack gap={2}>
                <Stack gap={1} direction='horizontal' alignItems='center'>
                  <Image source={{ uri: singleNetwork }} size={8} />

                  <Text variant='heroLight' size={7}>
                    Networks
                  </Text>
                </Stack>

                <Text size={4} variant='headingLight'>
                  Communities don't exist in isolation.
                </Text>

                <Text size={4} variant='onSurface'>
                  Whether it's{' '}
                  <Icon
                    name='heart-filled'
                    variant='networkVisualText'
                    size={3.5}
                  />{' '}
                  interest-based,{' '}
                  <Icon
                    name='globe-filled'
                    variant='networkVisualText'
                    size={3.5}
                  />{' '}
                  geographically, or by{' '}
                  <Icon
                    name='star-filled'
                    variant='networkVisualText'
                    size={3.5}
                  />{' '}
                  brand, your community can accurately represent its
                  intersectionality via Networks enabling cross-promotion and
                  content sharing.
                </Text>
              </Stack>

              <Stack gap={2}>
                <Stack gap={1} direction='horizontal' alignItems='center'>
                  <Image source={{ uri: singleGuild }} size={8} />

                  <Text variant='heroLight' size={7}>
                    Guilds
                  </Text>
                </Stack>

                <Text size={4} variant='headingLight'>
                  The home for your community.
                </Text>

                <Text size={4} variant='onSurface'>
                  Every Guild can host its own Events and create a portfolio of
                  Presentations. Keep your members notified and engaged in the
                  fun!
                </Text>

                <Stack />
              </Stack>
            </Stack>
          </Column>

          <Column span={2}>
            <ErrorBoundary
              fallback={
                <Image
                  source={{ uri: guildsNetworksVisualFull }}
                  style={{ flex: 1 }}
                  resizeMode='contain'
                />
              }
            >
              <Stack alignItems='center'>
                <NetworkVisual />
              </Stack>
            </ErrorBoundary>
          </Column>
        </Grid>
      </Breakpoint>
    </Card>
  )
}

const NetworkVisual: FC = () => {
  const canvas = useRef<HTMLCanvasElement>()
  const breakpoint = useBreakpoint()

  const network1 = useRef<SVGPathElement>()
  const network2 = useRef<SVGPathElement>()
  const network3 = useRef<SVGPathElement>()

  const [network1Dimensions, setNetwork1Dimensions] =
    useState<LayoutRectangle>()
  const [network2Dimensions, setNetwork2Dimensions] =
    useState<LayoutRectangle>()
  const [network3Dimensions, setNetwork3Dimensions] =
    useState<LayoutRectangle>()

  const guild1 = useRef<SVGRectElement>()
  const guild2 = useRef<SVGRectElement>()
  const guild3 = useRef<SVGRectElement>()
  const guild4 = useRef<SVGRectElement>()
  const guild5 = useRef<SVGRectElement>()

  const [guild1Dimensions, setGuild1Dimensions] = useState<LayoutRectangle>()
  const [guild2Dimensions, setGuild2Dimensions] = useState<LayoutRectangle>()
  const [guild3Dimensions, setGuild3Dimensions] = useState<LayoutRectangle>()
  const [guild4Dimensions, setGuild4Dimensions] = useState<LayoutRectangle>()
  const [guild5Dimensions, setGuild5Dimensions] = useState<LayoutRectangle>()

  const hoveredObject = useRef<{
    objectId:
      | 'network1'
      | 'network2'
      | 'network3'
      | 'guild1'
      | 'guild2'
      | 'guild3'
      | 'guild4'
      | 'guild5'
    timing: number
  } | null>(null)

  useEffect(() => {
    if (canvas.current) {
      const width = canvas.current.width
      const height = canvas.current.height
      const context = canvas.current.getContext('2d')

      if (!context) {
        return
      }

      const ANIMATION_SLOWDOWN = 1

      const SVG_PATHS = [
        'M 270 113 v 5.62 c 0 27.567 -22.313 49.934 -49.88 50 l -108.74 0.26 c -27.567 0.066 -45.88 22.433 -49.38 41.12 V 211',
        'M366.5 113v5.624c0 27.566-22.31 49.931-49.876 50l-101.748.252c-27.566.069-49.876 22.434-49.876 50V211',
        'M270 113v5.621c0 27.567 22.312 49.933 49.879 50l106.742.258c27.567.067 49.879 22.433 49.879 50V211',
        'M173 113v5.582c0 27.582 22.336 49.955 49.918 50l203.664.336c27.582.045 49.918 22.418 49.918 50V211M270 113l-1 107',
        'M270 113v5.741c0 27.52 22.239 49.866 49.758 49.999l3.984.02c27.519.133 49.758 22.479 49.758 49.999V211',
        'M 173 113 v 5.582 c 0 27.582 22.336 49.955 49.918 50 l 203.664 0.336 c 27.582 0.045 49.918 22.418 49.918 50 V 211',
        'M 270 113 l -1 107',
        'M 173 113 v 5.582 c 0 27.582 22.336 49.955 49.918 50 l 100.000 0.02 c 27.519 0.133 49.758 22.476 49.758 49.999 V 211',
        'M 366.5 113 v 5.624 c 0 27.566 -22.31 49.931 -49.876 50 l -205 0.252 c -27.567 0.066 -45.88 22.433 -49.38 41.12 V 211',
      ] as const

      const PATHS = SVG_PATHS.map((path) => new Path2D(path))

      const PATH_LENGTHS = SVG_PATHS.map((path) => {
        const properties = new svgPathProperties(path)

        return properties.getTotalLength()
      })

      const NETWORK_PATHS = [
        new Path2D(
          'M173.875 20.237l38.868 22.44a2.75 2.75 0 011.375 2.382v44.882a2.75 2.75 0 01-1.375 2.381l-38.868 22.441a2.751 2.751 0 01-2.75 0l-38.868-22.44a2.75 2.75 0 01-1.375-2.382V45.059c0-.982.524-1.89 1.375-2.381l38.868-22.44a2.75 2.75 0 012.75 0z'
        ),
        new Path2D(
          'M270.875 20.237l38.868 22.44a2.75 2.75 0 011.375 2.382v44.882a2.75 2.75 0 01-1.375 2.381l-38.868 22.441a2.751 2.751 0 01-2.75 0l-38.868-22.44a2.75 2.75 0 01-1.375-2.382V45.059c0-.982.524-1.89 1.375-2.381l38.868-22.44a2.75 2.75 0 012.75 0z'
        ),
        new Path2D(
          'M367.875 20.237l38.868 22.44a2.75 2.75 0 011.375 2.382v44.882a2.75 2.75 0 01-1.375 2.381l-38.868 22.441a2.751 2.751 0 01-2.75 0l-38.868-22.44a2.75 2.75 0 01-1.375-2.382V45.059c0-.982.524-1.89 1.375-2.381l38.868-22.44a2.75 2.75 0 012.75 0z'
        ),
      ] as const

      const NETWORK_FILLS = [
        new Path2D(
          'M170.5 19.155a4 4 0 014 0l38.868 22.44a4 4 0 012 3.464v44.882a4 4 0 01-2 3.464l-38.868 22.44a3.998 3.998 0 01-4 0l-38.868-22.44a4 4 0 01-2-3.464V45.059a4 4 0 012-3.464l38.868-22.44z'
        ),
        new Path2D(
          'M267.5 19.155a4 4 0 014 0l38.868 22.44a4 4 0 012 3.464v44.882a4 4 0 01-2 3.464l-38.868 22.44a3.998 3.998 0 01-4 0l-38.868-22.44a4 4 0 01-2-3.464V45.059a4 4 0 012-3.464l38.868-22.44z'
        ),
        new Path2D(
          'M364.5 19.155a4 4 0 014 0l38.868 22.44a4 4 0 012 3.464v44.882a4 4 0 01-2 3.464l-38.868 22.44a3.998 3.998 0 01-4 0l-38.868-22.44a4 4 0 01-2-3.464V45.059a4 4 0 012-3.464l38.868-22.44z'
        ),
      ]

      const GUILD_RECTANGLES = [
        { width: 86, height: 86, x: 18, y: 220 },
        { width: 86, height: 86, x: 122, y: 220 },
        { width: 86, height: 86, x: 226, y: 220 },
        { width: 86, height: 86, x: 330, y: 220 },
        { width: 86, height: 86, x: 434, y: 220 },
      ]

      const GUILD_BORDERS = [
        { width: 83, height: 83, x: 19.5, y: 221.5 },
        { width: 83, height: 83, x: 123.5, y: 221.5 },
        { width: 83, height: 83, x: 227.5, y: 221.5 },
        { width: 83, height: 83, x: 331.5, y: 221.5 },
        { width: 83, height: 83, x: 435.5, y: 221.5 },
      ]

      const dpr = PixelRatio.get()
      context.scale(dpr, dpr)

      let highlightNetworks: Record<0 | 1 | 2, number> = {
        0: 0,
        1: 0,
        2: 0,
      }
      let highlightGuilds: Record<0 | 1 | 2 | 3 | 4, number> = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
      }

      const renderNetworkSquare = (
        networkIndex: keyof typeof NETWORK_PATHS,
        highlight: number = 0
      ) => {
        const fillGradient = context.createLinearGradient(140, -61.5, 123, 100)
        fillGradient.addColorStop(0.186, '#FBFF2D')
        fillGradient.addColorStop(1, '#F960CE')
        context.fillStyle = fillGradient

        context.beginPath()
        context.setLineDash([300])
        context.lineDashOffset = 0
        context.fill(NETWORK_FILLS[networkIndex])

        context.globalCompositeOperation = 'overlay'

        context.lineWidth = 2.5
        if (highlight > 0) {
          context.strokeStyle = 'rgba(255, 207, 135, 0.75)'
        } else {
          context.strokeStyle = 'rgba(85, 51, 235, 0.75)'
        }

        context.beginPath()
        // context.lineJoin
        // context.setLineDash([286.567])
        context.lineDashOffset = 0
        context.stroke(NETWORK_PATHS[networkIndex])
        context.globalCompositeOperation = 'source-over'
      }

      const renderNetworkConnector = (
        x: number,
        y: number,
        radius: number,
        highlight: number = 0
      ) => {
        if (highlight > 0) {
          const radialGradient = context.createRadialGradient(
            x,
            y,
            radius + 30,
            x,
            y,
            radius
          )
          radialGradient.addColorStop(0, `rgba(255, 212, 203, 0)`)
          radialGradient.addColorStop(
            1,
            `rgba(255, 212, 203, ${0.5 * highlight})`
          )

          context.fillStyle = radialGradient
          context.filter = 'blur(12px)'
          context.arc(x, y, radius + 20, 0, 2 * Math.PI)
          context.fill()
        }

        context.filter = 'none'
        context.lineWidth = 2.5

        const strokeGradient = context.createLinearGradient(
          x,
          y - radius / 2,
          x,
          y + radius / 2
        )

        if (highlight > 0) {
          strokeGradient.addColorStop(0, 'rgba(250, 159, 185, 1)')
          strokeGradient.addColorStop(1, 'rgba(249, 53, 237, 1)')
        } else {
          strokeGradient.addColorStop(0.2, 'rgba(195, 56, 255, 0.51)')
          strokeGradient.addColorStop(1, 'rgb(123, 35, 222)')
        }

        context.strokeStyle = strokeGradient

        const fillGradient = context.createLinearGradient(
          x,
          y - radius / 2,
          x,
          y + radius / 2
        )
        if (highlight > 0) {
          fillGradient.addColorStop(0.2, 'rgba(251, 203, 98, 1)')
          fillGradient.addColorStop(1, 'rgba(255, 139, 139, 1)')
        } else {
          fillGradient.addColorStop(0, '#FF8B8B')
          fillGradient.addColorStop(1, '#FF51F8')
        }

        context.fillStyle = fillGradient

        context.beginPath()
        context.lineDashOffset = 0
        context.arc(x, y, radius, 0, 2 * Math.PI)

        // context.shadowColor = `rgba(255, 139, 139, ${highlight})`

        // context.shadowColor = `rgba(255, 212, 203, ${highlight})`
        // context.shadowBlur = 30

        context.fill()

        // context.shadowBlur = 0

        context.beginPath()
        context.setLineDash([100, 0])
        context.lineDashOffset = 0
        context.arc(x, y, radius, 0, 2 * Math.PI)
        context.stroke()
      }

      const renderGuildSquare = (
        guildIndex: keyof typeof GUILD_RECTANGLES & keyof typeof GUILD_BORDERS,
        highlight: number = 0
      ) => {
        const guildRectangle = GUILD_RECTANGLES[guildIndex]
        const guildBorder = GUILD_BORDERS[guildIndex]

        if (highlight > 0) {
          context.fillStyle = '#FF67C2'
        } else {
          const fillGradient = context.createLinearGradient(
            guildRectangle.x + guildRectangle.width / 2,
            guildRectangle.y,
            guildRectangle.x + guildRectangle.width / 2,
            guildRectangle.y + guildRectangle.height
          )
          fillGradient.addColorStop(0, '#FF67C2')
          fillGradient.addColorStop(1, '#D737FF')
          context.fillStyle = fillGradient
        }

        context.beginPath()
        context.setLineDash([300])
        context.lineDashOffset = 0
        context.roundRect(
          guildRectangle.x,
          guildRectangle.y,
          guildRectangle.width,
          guildRectangle.height,
          24
        )
        context.fill()

        context.globalCompositeOperation = 'overlay'

        context.lineWidth = 2.5
        if (highlight > 0) {
          context.strokeStyle = 'rgba(255, 207, 135, 0.75)'
        } else {
          context.strokeStyle = 'rgba(85, 51, 235, 0.75)'
        }

        context.beginPath()
        // context.lineJoin
        // context.setLineDash([286.567])
        context.lineDashOffset = 0
        context.roundRect(
          guildBorder.x,
          guildBorder.y,
          guildBorder.width,
          guildBorder.height,
          22.5
        )

        context.stroke()
        context.globalCompositeOperation = 'source-over'
      }

      const renderGuildConnector = (
        x: number,
        y: number,
        radius: number,
        highlight: number = 0
      ) => {
        if (highlight > 0) {
          const radialGradient = context.createRadialGradient(
            x,
            y,
            radius + 30,
            x,
            y,
            radius
          )
          radialGradient.addColorStop(0, `rgba(255, 212, 203, 0)`)
          radialGradient.addColorStop(
            1,
            `rgba(255, 212, 203, ${0.5 * highlight})`
          )

          context.fillStyle = radialGradient
          context.filter = 'blur(12px)'
          context.arc(x, y, radius + 20, 0, 2 * Math.PI)
          context.fill()
        }

        context.filter = 'none'

        context.lineWidth = 2

        const strokeGradient = context.createLinearGradient(
          x,
          y - radius / 2,
          x,
          y + radius / 2
        )
        if (highlight > 0) {
          strokeGradient.addColorStop(0, 'rgba(250, 159, 185, 1)')
          strokeGradient.addColorStop(1, 'rgba(249, 53, 237, 1)')
        } else {
          strokeGradient.addColorStop(0.2, 'rgba(195, 56, 255, 0.51)')
          strokeGradient.addColorStop(1, 'rgb(123, 35, 222)')
        }

        context.strokeStyle = strokeGradient

        const fillGradient = context.createLinearGradient(
          x,
          y - radius / 2,
          x,
          y + radius / 2
        )
        if (highlight > 0) {
          fillGradient.addColorStop(0.2, 'rgba(251, 203, 98, 1)')
          fillGradient.addColorStop(1, 'rgba(255, 139, 139, 1)')
        } else {
          fillGradient.addColorStop(0, '#FF8B8B')
          fillGradient.addColorStop(1, '#FF51F8')
        }
        context.fillStyle = fillGradient

        context.beginPath()
        context.lineDashOffset = 0
        context.arc(x, y, radius, 0, 2 * Math.PI)

        // context.shadowColor = `rgba(255, 139, 139, ${highlight})`
        context.shadowColor = `rgba(255, 255, 255, ${highlight})`
        context.shadowBlur = 50

        context.fill()

        context.shadowBlur = 0

        context.beginPath()
        context.setLineDash([100, 0])
        context.lineDashOffset = 0
        context.arc(x, y, radius, 0, 2 * Math.PI)
        context.stroke()
      }

      const renderNetworkConnectors = (
        timing: number
        // highlightConnectors: {
        //   [index: number]: [number, number]
        // } = {}
      ) => {
        renderNetworkSquare(
          0,
          hoveredObject.current?.objectId === 'network1' ||
            highlightNetworks[0] === 1.0
            ? 1.0
            : 0.0
        )
        renderNetworkSquare(
          1,
          hoveredObject.current?.objectId === 'network2' ||
            highlightNetworks[1] === 1.0
            ? 1.0
            : 0.0
        )
        renderNetworkSquare(
          2,
          hoveredObject.current?.objectId === 'network3' ||
            highlightNetworks[2] === 1.0
            ? 1.0
            : 0.0
        )

        renderNetworkConnector(173, 113, 8, highlightNetworks[0])
        renderNetworkConnector(270, 113, 8, highlightNetworks[1])
        renderNetworkConnector(367, 113, 8, highlightNetworks[2])
      }

      const renderGuildConnectors = (
        // highlightConnectors: {
        //   [index: number]: [number, number]
        // } = {},
        timing: number
      ) => {
        renderGuildSquare(
          0,
          hoveredObject.current?.objectId === 'guild1' ||
            highlightGuilds[0] === 1.0
            ? 1.0
            : 0.0
        )
        renderGuildSquare(
          1,
          hoveredObject.current?.objectId === 'guild2' ||
            highlightGuilds[1] === 1.0
            ? 1.0
            : 0.0
        )
        renderGuildSquare(
          2,
          hoveredObject.current?.objectId === 'guild3' ||
            highlightGuilds[2] === 1.0
            ? 1.0
            : 0.0
        )
        renderGuildSquare(
          3,
          hoveredObject.current?.objectId === 'guild4' ||
            highlightGuilds[3] === 1.0
            ? 1.0
            : 0.0
        )
        renderGuildSquare(
          4,
          hoveredObject.current?.objectId === 'guild5' ||
            highlightGuilds[4] === 1.0
            ? 1.0
            : 0.0
        )

        renderGuildConnector(61, 220, 7, highlightGuilds[0])
        renderGuildConnector(165, 220, 7, highlightGuilds[1])
        renderGuildConnector(269, 220, 7, highlightGuilds[2])
        renderGuildConnector(373, 220, 7, highlightGuilds[3])
        renderGuildConnector(477, 220, 7, highlightGuilds[4])
      }

      let playingAnimation = true

      const renderItems: Record<string, (timing: number) => any> = {}

      const connectPath = async ({
        pathIndex,
        startTiming,
        duration = 1000 * ANIMATION_SLOWDOWN,
        lineDash = PATH_LENGTHS[pathIndex],
        guilds = [],
        networks = [],
      }: {
        pathIndex: keyof typeof PATHS
        startTiming: number
        lineDash?: number
        duration?: number
        networks?: number[]
        guilds?: number[]
      }) => {
        await new Promise<void>((resolve) => {
          if (hoveredObject.current) {
            const itemKey = `connect-${pathIndex}`
            const renderedObject = hoveredObject.current.objectId

            renderItems[itemKey] = (timing: number) => {
              const currentTiming = timing - startTiming

              context.strokeStyle = 'rgba(251, 202, 99, 0.8)'
              context.lineJoin = 'round'
              context.lineWidth = 2

              context.beginPath()
              context.setLineDash([lineDash])
              context.lineDashOffset = 0
              // context.lineDashOffset = interpolate(
              //   currentTiming,
              //   [0, duration],
              //   [lineDash, 0],
              //   Extrapolate.CLAMP
              // )
              context.stroke(PATHS[pathIndex] as Path2D)

              if (networks) {
                for (const network of networks) {
                  highlightNetworks[network] = 1.0
                }
              }

              if (guilds) {
                for (const guild of guilds) {
                  highlightGuilds[guild] = 1.0
                }
              }

              if (
                !hoveredObject.current ||
                hoveredObject.current.objectId !== renderedObject
              ) {
                delete renderItems[itemKey]

                resolve()
              }
            }
          }
        })
      }

      const renderHovered = (timing: number) => {
        if (hoveredObject.current) {
          playingAnimation = false

          if (hoveredObject.current.objectId === 'network1') {
            if (!renderItems['connect-5']) {
              connectPath({
                pathIndex: 5,
                startTiming: hoveredObject.current.timing,
                lineDash: 400,
                networks: [0],
                guilds: [4],
              })
            }

            if (!renderItems['connect-7']) {
              connectPath({
                pathIndex: 7,
                startTiming: hoveredObject.current.timing,
                networks: [0],
                guilds: [3],
              })
            }
          }

          if (hoveredObject.current.objectId === 'network2') {
            if (!renderItems['connect-0']) {
              connectPath({
                pathIndex: 0,
                startTiming: hoveredObject.current.timing,
                networks: [1],
                guilds: [0],
              })
            }

            // if (!renderItems['connect-2']) {
            //   connectPath({
            //     pathIndex: 2,
            //     startTiming: hoveredObject.current.timing,
            //     networks: [1],
            //     guilds: [4],
            //   })
            // }

            if (!renderItems['connect-4']) {
              connectPath({
                pathIndex: 4,
                startTiming: hoveredObject.current.timing,
                networks: [1],
                guilds: [3],
              })
            }

            if (!renderItems['connect-6']) {
              connectPath({
                pathIndex: 6,
                startTiming: hoveredObject.current.timing,
                networks: [1],
                guilds: [2],
              })
            }
          }

          if (hoveredObject.current.objectId === 'network3') {
            if (!renderItems[`connect-1`]) {
              connectPath({
                pathIndex: 1,
                startTiming: hoveredObject.current.timing,
                networks: [2],
                guilds: [1],
              })
            }

            if (!renderItems[`connect-8`]) {
              connectPath({
                pathIndex: 8,
                startTiming: hoveredObject.current.timing,
                networks: [2],
                guilds: [0],
              })
            }
          }

          if (hoveredObject.current.objectId === 'guild1') {
            if (!renderItems[`connect-0`]) {
              connectPath({
                pathIndex: 0,
                startTiming: hoveredObject.current.timing,
                networks: [1],
                guilds: [0],
              })
            }

            if (!renderItems[`connect-8`]) {
              connectPath({
                pathIndex: 8,
                startTiming: hoveredObject.current.timing,
                networks: [2],
                guilds: [0],
              })
            }
          }

          if (
            hoveredObject.current.objectId === 'guild2' &&
            !renderItems['connect-1']
          ) {
            connectPath({
              pathIndex: 1,
              startTiming: hoveredObject.current.timing,
              networks: [2],
              guilds: [1],
            })
          }

          if (
            hoveredObject.current.objectId === 'guild3' &&
            !renderItems['connect-6']
          ) {
            connectPath({
              pathIndex: 6,
              startTiming: hoveredObject.current.timing,
              networks: [1],
              guilds: [2],
            })
          }

          if (hoveredObject.current.objectId === 'guild4') {
            if (!renderItems['connect-4']) {
              connectPath({
                pathIndex: 4,
                startTiming: hoveredObject.current.timing,
                networks: [1],
                guilds: [3],
              })
            }

            if (!renderItems['connect-7']) {
              connectPath({
                pathIndex: 7,
                startTiming: hoveredObject.current.timing,
                networks: [0],
                guilds: [3],
              })
            }
          }

          if (hoveredObject.current.objectId === 'guild5') {
            if (!renderItems['connect-5']) {
              connectPath({
                pathIndex: 5,
                startTiming: hoveredObject.current.timing,
                lineDash: 400,
                networks: [0],
                guilds: [4],
              })
            }
          }
        } else {
          playingAnimation = true
        }
      }

      const animatePath = ({
        pathIndex,
        start,
        end,
        duration = 1000 * ANIMATION_SLOWDOWN,
        lineDash = PATH_LENGTHS[pathIndex],

        fromConnectors,
        toConnectors,
        callback,
      }: {
        pathIndex: keyof typeof PATHS
        start: number
        end: number
        duration?: number
        lineDash?: number
        fromConnectors?: { network?: number[]; guild?: number[] }
        toConnectors?: { network?: number[]; guild?: number[] }

        callback?: () => any
      }) => {
        const startTiming = performance.now()

        const halfDuration = duration / 2

        return (timing: number) => {
          const currentTiming = timing - startTiming

          context.strokeStyle = 'rgba(251, 202, 99, 0.8)'
          context.lineJoin = 'round'
          context.lineWidth = 2

          context.beginPath()
          context.setLineDash([lineDash])
          context.lineDashOffset = interpolate(
            currentTiming,
            [0, duration],
            [start, end]
          )
          context.stroke(PATHS[pathIndex] as Path2D)

          if (fromConnectors && currentTiming < halfDuration) {
            if (fromConnectors.network) {
              for (const networkIndex of fromConnectors.network) {
                highlightNetworks[networkIndex] = 0.5
              }
            }

            if (fromConnectors.guild) {
              for (const guildIndex of fromConnectors.guild) {
                highlightGuilds[guildIndex] = 0.5
              }
            }
          }

          if (toConnectors && currentTiming >= halfDuration) {
            if (toConnectors.network) {
              for (const networkIndex of toConnectors.network) {
                highlightNetworks[networkIndex] = 0.5
              }
            }

            if (toConnectors.guild) {
              for (const guildIndex of toConnectors.guild) {
                highlightGuilds[guildIndex] = 0.5
              }
            }
          }

          if (timing > startTiming + duration) {
            if (callback) {
              callback()
            }
          }
        }
      }

      let inEffect = true

      const ANIMATIONS = [
        (timing: number, callback?: () => any) => {
          if (!renderItems['animation-0']) {
            renderItems['animation-0'] = animatePath({
              pathIndex: 0,
              start: PATH_LENGTHS[0] * -1,
              end: PATH_LENGTHS[0] * 1,
              callback: () => {
                delete renderItems['animation-0']

                if (callback) {
                  callback()
                }
              },

              fromConnectors: { guild: [0] },
              toConnectors: { network: [1] },
            })
          }
        },
        (timing: number, callback?: () => any) => {
          if (!renderItems['animation-1']) {
            let animation1Complete = false,
              animation2Complete = false,
              animation3Complete = false

            const animation1 = animatePath({
              pathIndex: 6,

              start: PATH_LENGTHS[6],
              end: PATH_LENGTHS[6] * -1,

              fromConnectors: { network: [1] },
              toConnectors: { guild: [2] },

              callback: () => {
                animation1Complete = true

                if (animation2Complete && animation3Complete) {
                  delete renderItems['animation-1']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            const animation2 = animatePath({
              pathIndex: 4,

              start: PATH_LENGTHS[4],
              end: PATH_LENGTHS[4] * -1,

              fromConnectors: { network: [1] },
              toConnectors: { guild: [3] },

              callback: () => {
                animation2Complete = true

                if (animation1Complete && animation3Complete) {
                  delete renderItems['animation-1']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            const animation3 = animatePath({
              pathIndex: 0,
              start: PATH_LENGTHS[0],
              end: PATH_LENGTHS[0] * -1,

              fromConnectors: { network: [1] },
              toConnectors: { guild: [0] },

              callback: () => {
                animation3Complete = true

                if (animation1Complete && animation2Complete) {
                  delete renderItems['animation-1']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            renderItems['animation-1'] = (timing: number) => {
              animation1(timing)
              animation2(timing)
              animation3(timing)
            }
          }
        },
        (timing: number, callback?: () => any) => {
          if (!renderItems['animation-2']) {
            let animation1Complete = false,
              animation2Complete = false

            const animation1 = animatePath({
              pathIndex: 1,
              start: PATH_LENGTHS[1],
              end: PATH_LENGTHS[1] * -1,

              fromConnectors: { network: [2] },
              toConnectors: { guild: [1] },

              callback: () => {
                animation1Complete = true

                if (animation2Complete) {
                  delete renderItems['animation-2']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            const animation2 = animatePath({
              pathIndex: 8,
              start: PATH_LENGTHS[8],
              end: PATH_LENGTHS[8] * -1,

              fromConnectors: { network: [2] },
              toConnectors: { guild: [0] },

              callback: () => {
                animation2Complete = true

                if (animation1Complete) {
                  delete renderItems['animation-2']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            renderItems['animation-2'] = (timing: number) => {
              animation1(timing)
              animation2(timing)
            }
          }
        },

        (timing: number, callback?: () => any) => {
          if (!renderItems['animation-5']) {
            let animation1Complete = false,
              animation2Complete = false

            const animation1 = animatePath({
              pathIndex: 5,

              start: PATH_LENGTHS[5] * -1,
              end: PATH_LENGTHS[5],

              fromConnectors: { guild: [4] },
              toConnectors: { network: [0] },

              callback: () => {
                animation1Complete = true

                if (animation2Complete) {
                  delete renderItems['animation-5']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            const animation2 = animatePath({
              pathIndex: 6,

              start: PATH_LENGTHS[6] * -1,
              end: PATH_LENGTHS[6],

              fromConnectors: { guild: [2] },
              toConnectors: { network: [1] },

              callback: () => {
                animation2Complete = true

                if (animation1Complete) {
                  delete renderItems['animation-5']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            renderItems['animation-5'] = (timing: number) => {
              animation1(timing)
              animation2(timing)
            }
          }
        },
        (timing: number, callback?: () => any) => {
          if (!renderItems['animation-3']) {
            let animation1Complete = false,
              animation2Complete = false

            const animation1 = animatePath({
              pathIndex: 5,

              start: PATH_LENGTHS[5],
              end: PATH_LENGTHS[5] * -1,

              fromConnectors: { network: [0] },
              toConnectors: { guild: [4] },

              callback: () => {
                animation1Complete = true

                if (animation2Complete) {
                  delete renderItems['animation-3']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            const animation2 = animatePath({
              pathIndex: 7,

              start: PATH_LENGTHS[7],
              end: PATH_LENGTHS[7] * -1,

              fromConnectors: { network: [0] },
              toConnectors: { guild: [3] },

              callback: () => {
                animation2Complete = true

                if (animation1Complete) {
                  delete renderItems['animation-3']

                  if (callback) {
                    callback()
                  }
                }
              },
            })

            renderItems['animation-3'] = (timing: number) => {
              animation1(timing)
              animation2(timing)
            }
          }
        },
      ]

      let currentAnimation = 0

      const renderAnimations = (timing: number) => {
        if (playingAnimation && !renderItems[`animation-${currentAnimation}`]) {
          ANIMATIONS[currentAnimation](timing, () => {
            currentAnimation = (currentAnimation + 1) % ANIMATIONS.length
          })
        }
      }

      let intersectingWithGraphic = true

      let intersectionObserver: IntersectionObserver | undefined

      const render = async () => {
        await new Promise<void>((resolve) => {
          highlightNetworks = {
            0: 0,
            1: 0,
            2: 0,
          }

          highlightGuilds = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
          }

          requestAnimationFrame((timing) => {
            context.clearRect(0, 0, width, height)

            for (const item of Object.values(renderItems)) {
              item(timing)
            }

            renderHovered(timing)
            renderAnimations(timing)

            renderNetworkConnectors(timing)
            renderGuildConnectors(timing)

            resolve()
          })
        })
      }

      const animate = async () => {
        while (inEffect && intersectingWithGraphic) {
          await render()
        }
      }

      if (typeof window !== 'undefined' && window.IntersectionObserver) {
        intersectingWithGraphic = false

        intersectionObserver = new window.IntersectionObserver(([entry]) => {
          if (entry.isIntersecting && !intersectingWithGraphic) {
            intersectingWithGraphic = entry.isIntersecting

            animate()
          } else {
            intersectingWithGraphic = entry.isIntersecting
          }
        })

        intersectionObserver.observe(canvas.current)
      } else {
        animate()
      }

      return () => {
        inEffect = false

        if (intersectionObserver) {
          intersectionObserver.disconnect()
        }
      }
    }
  }, [breakpoint])

  return (
    <View
      style={{ width: '100%', aspectRatio: 538 / 324 }}
      onLayout={({ nativeEvent: { layout } }) => {
        const calculateDimensions = (
          element: SVGPathElement | SVGRectElement
        ) => {
          const dims = element.getBoundingClientRect()

          return {
            width: dims.width,
            height: dims.height,
            top: dims.top - layout.top,
            left: dims.left - layout.left,
            x: dims.x,
            y: dims.y,
          }
        }

        if (network1.current) {
          setNetwork1Dimensions(calculateDimensions(network1.current))
        }

        if (network2.current) {
          setNetwork2Dimensions(calculateDimensions(network2.current))
        }

        if (network3.current) {
          setNetwork3Dimensions(calculateDimensions(network3.current))
        }

        if (guild1.current) {
          setGuild1Dimensions(calculateDimensions(guild1.current))
        }

        if (guild2.current) {
          setGuild2Dimensions(calculateDimensions(guild2.current))
        }

        if (guild3.current) {
          setGuild3Dimensions(calculateDimensions(guild3.current))
        }

        if (guild4.current) {
          setGuild4Dimensions(calculateDimensions(guild4.current))
        }

        if (guild5.current) {
          setGuild5Dimensions(calculateDimensions(guild5.current))
        }
      }}
    >
      {Object.entries({
        network1: network1Dimensions,
        network2: network2Dimensions,
        network3: network3Dimensions,
        guild1: guild1Dimensions,
        guild2: guild2Dimensions,
        guild3: guild3Dimensions,
        guild4: guild4Dimensions,
        guild5: guild5Dimensions,
      } as const).map(([objectId, dimensions], index) => {
        if (!dimensions) {
          return null
        }

        return (
          <View
            key={index}
            style={{
              position: 'absolute',
              // borderWidth: 2,
              // borderStyle: 'dashed',
              // borderColor: '#ffffff',
              // padding: 16,
              borderRadius: 24,
              width: dimensions.width,
              height: dimensions.height,
              top: dimensions.top,
              left: dimensions.left,
              zIndex: 100,
              alignItems: 'center',
              justifyContent: 'center',
              mixBlendMode: objectId.startsWith('network')
                ? 'overlay'
                : 'hard-light',
            }}
            onMouseEnter={() => {
              hoveredObject.current = { objectId, timing: performance.now() }
            }}
            onMouseLeave={() => {
              if (
                hoveredObject.current &&
                hoveredObject.current.objectId === objectId
              ) {
                hoveredObject.current = null
              }
            }}
          >
            {(() => {
              switch (ICON_CONTENT[objectId].type) {
                case 'icon': {
                  return (
                    <Icon
                      name={ICON_CONTENT[objectId].name}
                      variant='networkVisual'
                      size={selectBreakpointToken(breakpoint, [5, 8, 10])}
                    />
                  )
                }

                case 'image': {
                  return (
                    <Link to={ICON_CONTENT[objectId].link}>
                      <Image
                        source={{ uri: ICON_CONTENT[objectId].src }}
                        style={{
                          width: selectBreakpointToken(
                            breakpoint,
                            [24, 36, 48]
                          ),
                          aspectRatio: 1,
                          filter:
                            objectId === 'guild3'
                              ? 'saturate(0) brightness(0.5)'
                              : 'saturate(0)',
                          borderRadius: selectBreakpointToken(
                            breakpoint,
                            [4, 8]
                          ),
                        }}
                      />
                    </Link>
                  )
                }
              }
            })()}
          </View>
        )
      })}

      <canvas
        style={{
          position: 'absolute',
          width: '100%',
          aspectRatio: 538 / 324,
          zIndex: 10,
          imageRendering: 'smooth',
        }}
        width={538 * PixelRatio.get()}
        height={324 * PixelRatio.get()}
        key={breakpoint}
        ref={canvas}
      />

      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='538'
        height='324'
        fill='none'
        viewBox='0 0 538 324'
        style={{
          position: 'absolute',
          width: '100%',
          height: 'auto',
          zIndex: 0,
        }}
      >
        <g clipPath='url(#clip0_4257_3909)'>
          <path
            stroke='url(#paint0_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='85'
            d='M270 64v54.62c0 27.567-22.313 49.934-49.88 50l-108.74.26c-27.567.066-49.88 22.433-49.88 50V260'
            opacity='0.3'
          ></path>
          <path
            stroke='url(#paint1_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='85'
            d='M366.5 91v27.624c0 27.566-22.31 49.931-49.876 50l-101.748.252c-27.566.069-49.876 22.434-49.876 50V240.5'
            opacity='0.3'
          ></path>
          <path
            stroke='url(#paint2_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='85'
            d='M270 91v27.621c0 27.567 22.312 49.933 49.879 50l106.742.258c27.567.067 49.879 22.433 49.879 50V240.5'
            opacity='0.3'
          ></path>
          <path
            stroke='url(#paint3_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='85'
            d='M173 91v27.582c0 27.582 22.336 49.955 49.918 50l203.664.336c27.582.045 49.918 22.418 49.918 50V240.5'
            opacity='0.3'
          ></path>
          <path
            stroke='url(#paint4_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='85'
            d='M270 91v27.741c0 27.52 22.239 49.866 49.758 49.999l3.984.02c27.519.133 49.758 22.479 49.758 49.999V240.5'
            opacity='0.3'
          ></path>
          <path
            stroke='url(#paint5_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='8'
            d='M270 113v5.62c0 27.567-22.313 49.934-49.88 50l-108.74.26c-27.567.066-49.88 22.433-49.88 50V260'
          ></path>
          <path
            stroke='url(#paint6_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='8'
            d='M366.5 113v5.624c0 27.566-22.31 49.931-49.876 50l-101.748.252c-27.566.069-49.876 22.434-49.876 50V240.5'
          ></path>
          <path
            stroke='url(#paint7_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='8'
            d='M270 113v5.621c0 27.567 22.312 49.933 49.879 50l106.742.258c27.567.067 49.879 22.433 49.879 50V240.5'
          ></path>
          <path
            stroke='url(#paint8_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='8'
            d='M173 113v5.582c0 27.582 22.336 49.955 49.918 50l203.664.336c27.582.045 49.918 22.418 49.918 50V240.5'
          ></path>
          <path
            stroke='url(#paint9_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='8'
            d='M270 113l-1 107'
          ></path>
          <path
            stroke='url(#paint10_linear_4257_3909)'
            strokeLinejoin='round'
            strokeWidth='8'
            d='M270 113v5.741c0 27.52 22.239 49.866 49.758 49.999l3.984.02c27.519.133 49.758 22.479 49.758 49.999V240.5'
          ></path>

          <g filter='url(#filter0_d_4257_3909)'>
            <rect
              width='315'
              height='135'
              x='112'
              fill='url(#paint11_linear_4257_3909)'
              fillOpacity='0.2'
              rx='30'
              shapeRendering='crispEdges'
            ></rect>
            <g filter='url(#filter1_dd_4257_3909)'>
              <path
                ref={network1}
                opacity='0'
                fill='url(#paint12_linear_4257_3909)'
                d='M170.5 19.155a4 4 0 014 0l38.868 22.44a4 4 0 012 3.464v44.882a4 4 0 01-2 3.464l-38.868 22.44a3.998 3.998 0 01-4 0l-38.868-22.44a4 4 0 01-2-3.464V45.059a4 4 0 012-3.464l38.868-22.44z'
              ></path>
              {/* <path
                stroke='#5533EB'
                strokeOpacity='0.75'
                strokeWidth='2.5'
                d='M173.875 20.237l38.868 22.44a2.75 2.75 0 011.375 2.382v44.882a2.75 2.75 0 01-1.375 2.381l-38.868 22.441a2.751 2.751 0 01-2.75 0l-38.868-22.44a2.75 2.75 0 01-1.375-2.382V45.059c0-.982.524-1.89 1.375-2.381l38.868-22.44a2.75 2.75 0 012.75 0z'
                style={{ mixBlendMode: 'overlay' }}
              ></path> */}
            </g>
            {/* <g filter='url(#filter2_dd_4257_3909)'>
              <circle
                cx='173'
                cy='113'
                r='9'
                fill='url(#paint13_linear_4257_3909)'
              ></circle>
              <circle
                cx='173'
                cy='113'
                r='7.75'
                stroke='url(#paint14_linear_4257_3909)'
                strokeWidth='2.5'
              ></circle>
            </g> */}
            <g filter='url(#filter3_dd_4257_3909)'>
              <path
                ref={network2}
                opacity='0'
                fill='url(#paint15_linear_4257_3909)'
                d='M267.5 19.155a4 4 0 014 0l38.868 22.44a4 4 0 012 3.464v44.882a4 4 0 01-2 3.464l-38.868 22.44a3.998 3.998 0 01-4 0l-38.868-22.44a4 4 0 01-2-3.464V45.059a4 4 0 012-3.464l38.868-22.44z'
              ></path>
              {/* <path
                stroke='#5533EB'
                strokeOpacity='0.75'
                strokeWidth='2.5'
                d='M270.875 20.237l38.868 22.44a2.75 2.75 0 011.375 2.382v44.882a2.75 2.75 0 01-1.375 2.381l-38.868 22.441a2.751 2.751 0 01-2.75 0l-38.868-22.44a2.75 2.75 0 01-1.375-2.382V45.059c0-.982.524-1.89 1.375-2.381l38.868-22.44a2.75 2.75 0 012.75 0z'
                style={{ mixBlendMode: 'overlay' }}
              ></path> */}
            </g>
            {/* <g filter='url(#filter4_dd_4257_3909)'>
              <circle
                cx='270'
                cy='113'
                r='9'
                fill='url(#paint16_linear_4257_3909)'
              ></circle>
              <circle
                cx='270'
                cy='113'
                r='7.75'
                stroke='url(#paint17_linear_4257_3909)'
                strokeWidth='2.5'
              ></circle>
            </g> */}
            <g filter='url(#filter5_dd_4257_3909)'>
              <path
                ref={network3}
                opacity='0'
                fill='url(#paint18_linear_4257_3909)'
                d='M364.5 19.155a4 4 0 014 0l38.868 22.44a4 4 0 012 3.464v44.882a4 4 0 01-2 3.464l-38.868 22.44a3.998 3.998 0 01-4 0l-38.868-22.44a4 4 0 01-2-3.464V45.059a4 4 0 012-3.464l38.868-22.44z'
              ></path>
              {/* <path
                stroke='#5533EB'
                strokeOpacity='0.75'
                strokeWidth='2.5'
                d='M367.875 20.237l38.868 22.44a2.75 2.75 0 011.375 2.382v44.882a2.75 2.75 0 01-1.375 2.381l-38.868 22.441a2.751 2.751 0 01-2.75 0l-38.868-22.44a2.75 2.75 0 01-1.375-2.382V45.059c0-.982.524-1.89 1.375-2.381l38.868-22.44a2.75 2.75 0 012.75 0z'
                style={{ mixBlendMode: 'overlay' }}
              ></path> */}
            </g>
            {/* <g filter='url(#filter6_dd_4257_3909)'>
              <circle
                cx='367'
                cy='113'
                r='9'
                fill='url(#paint19_linear_4257_3909)'
              ></circle>
              <circle
                cx='367'
                cy='113'
                r='7.75'
                stroke='url(#paint20_linear_4257_3909)'
                strokeWidth='2.5'
              ></circle>
            </g> */}
            <rect
              width='313.875'
              height='133.875'
              x='112.562'
              y='0.563'
              stroke='#fff'
              strokeOpacity='0.15'
              strokeWidth='1.125'
              rx='29.438'
              shapeRendering='crispEdges'
            ></rect>
          </g>
          <g filter='url(#filter7_d_4257_3909)'>
            <rect
              width='538'
              height='122'
              y='202'
              fill='url(#paint21_linear_4257_3909)'
              fillOpacity='0.2'
              rx='30'
              shapeRendering='crispEdges'
            ></rect>
            <g filter='url(#filter8_dd_4257_3909)'>
              <rect
                ref={guild1}
                width='86'
                height='86'
                x='18'
                y='220'
                fill='url(#paint22_linear_4257_3909)'
                rx='24'
                shapeRendering='crispEdges'
                opacity='0'
              ></rect>
              {/* <g filter='url(#filter9_dd_4257_3909)'>
                <circle
                  cx='61'
                  cy='220'
                  r='9'
                  fill='url(#paint23_linear_4257_3909)'
                ></circle>
                <circle
                  cx='61'
                  cy='220'
                  r='8'
                  stroke='url(#paint24_linear_4257_3909)'
                  strokeWidth='2'
                ></circle>
              </g> */}
              <rect
                width='83'
                height='83'
                x='19.5'
                y='221.5'
                stroke='#5533EB'
                strokeWidth='3'
                rx='22.5'
                style={{ mixBlendMode: 'overlay' }}
                shapeRendering='crispEdges'
                opacity='0'
              ></rect>
            </g>
            <g filter='url(#filter10_dd_4257_3909)'>
              <rect
                ref={guild2}
                width='86'
                height='86'
                x='122'
                y='220'
                fill='url(#paint25_linear_4257_3909)'
                rx='24'
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
              {/* <g filter='url(#filter11_dd_4257_3909)'>
                <circle
                  cx='165'
                  cy='220'
                  r='9'
                  fill='url(#paint26_linear_4257_3909)'
                ></circle>
                <circle
                  cx='165'
                  cy='220'
                  r='8'
                  stroke='url(#paint27_linear_4257_3909)'
                  strokeWidth='2'
                ></circle>
              </g> */}
              <rect
                width='83'
                height='83'
                x='123.5'
                y='221.5'
                stroke='#5533EB'
                strokeWidth='3'
                rx='22.5'
                style={{ mixBlendMode: 'overlay' }}
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
            </g>
            <g filter='url(#filter12_dd_4257_3909)'>
              <rect
                ref={guild3}
                width='86'
                height='86'
                x='226'
                y='220'
                fill='url(#paint28_linear_4257_3909)'
                rx='24'
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
              {/* <g filter='url(#filter13_dd_4257_3909)'>
                <circle
                  cx='269'
                  cy='220'
                  r='9'
                  fill='url(#paint29_linear_4257_3909)'
                ></circle>
                <circle
                  cx='269'
                  cy='220'
                  r='8'
                  stroke='url(#paint30_linear_4257_3909)'
                  strokeWidth='2'
                ></circle>
              </g> */}
              <rect
                width='83'
                height='83'
                x='227.5'
                y='221.5'
                stroke='#5533EB'
                strokeWidth='3'
                rx='22.5'
                style={{ mixBlendMode: 'overlay' }}
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
            </g>
            <g filter='url(#filter14_dd_4257_3909)'>
              <rect
                ref={guild4}
                width='86'
                height='86'
                x='330'
                y='220'
                fill='url(#paint31_linear_4257_3909)'
                rx='24'
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
              {/* <g filter='url(#filter15_dd_4257_3909)'>
                <circle
                  cx='373'
                  cy='220'
                  r='9'
                  fill='url(#paint32_linear_4257_3909)'
                ></circle>
                <circle
                  cx='373'
                  cy='220'
                  r='8'
                  stroke='url(#paint33_linear_4257_3909)'
                  strokeWidth='2'
                ></circle>
              </g> */}
              <rect
                width='83'
                height='83'
                x='331.5'
                y='221.5'
                stroke='#5533EB'
                strokeWidth='3'
                rx='22.5'
                style={{ mixBlendMode: 'overlay' }}
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
            </g>
            <g filter='url(#filter16_dd_4257_3909)'>
              <rect
                ref={guild5}
                width='86'
                height='86'
                x='434'
                y='220'
                fill='url(#paint34_linear_4257_3909)'
                rx='24'
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
              {/* <g filter='url(#filter17_dd_4257_3909)'>
                <circle
                  cx='477'
                  cy='220'
                  r='9'
                  fill='url(#paint35_linear_4257_3909)'
                ></circle>
                <circle
                  cx='477'
                  cy='220'
                  r='8'
                  stroke='url(#paint36_linear_4257_3909)'
                  strokeWidth='2'
                ></circle>
              </g> */}
              <rect
                width='83'
                height='83'
                x='435.5'
                y='221.5'
                stroke='#5533EB'
                strokeWidth='3'
                rx='22.5'
                style={{ mixBlendMode: 'overlay' }}
                opacity='0'
                shapeRendering='crispEdges'
              ></rect>
            </g>
            <rect
              width='536.875'
              height='120.875'
              x='0.563'
              y='202.562'
              stroke='#fff'
              strokeOpacity='0.15'
              strokeWidth='1.125'
              rx='29.438'
              shapeRendering='crispEdges'
            ></rect>
          </g>
        </g>
        <defs>
          <filter
            id='filter0_d_4257_3909'
            width='321'
            height='141'
            x='109'
            y='-1'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='1.5'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect1_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter1_dd_4257_3909'
            width='117.736'
            height='129.763'
            x='113.632'
            y='5.619'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='3'></feOffset>
            <feGaussianBlur stdDeviation='8'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='1.5'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter2_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='153.333'
            y='95.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter3_dd_4257_3909'
            width='117.736'
            height='129.763'
            x='210.632'
            y='5.619'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='3'></feOffset>
            <feGaussianBlur stdDeviation='8'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='1.5'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter4_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='250.333'
            y='95.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter5_dd_4257_3909'
            width='117.736'
            height='129.763'
            x='307.632'
            y='5.619'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='3'></feOffset>
            <feGaussianBlur stdDeviation='8'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='1.5'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter6_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='347.333'
            y='95.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter7_d_4257_3909'
            width='544'
            height='128'
            x='-3'
            y='201'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='1.5'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect1_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter8_dd_4257_3909'
            width='107.333'
            height='116.333'
            x='7.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter9_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='41.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter10_dd_4257_3909'
            width='107.333'
            height='116.333'
            x='111.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter11_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='145.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter12_dd_4257_3909'
            width='107.333'
            height='116.333'
            x='215.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter13_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='249.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter14_dd_4257_3909'
            width='107.333'
            height='116.333'
            x='319.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter15_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='353.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter16_dd_4257_3909'
            width='107.333'
            height='116.333'
            x='423.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <filter
            id='filter17_dd_4257_3909'
            width='39.333'
            height='39.333'
            x='457.333'
            y='202.333'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='2'></feOffset>
            <feGaussianBlur stdDeviation='5.333'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.1 0'></feColorMatrix>
            <feBlend
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4257_3909'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset dy='1.333'></feOffset>
            <feGaussianBlur stdDeviation='1'></feGaussianBlur>
            <feComposite in2='hardAlpha' operator='out'></feComposite>
            <feColorMatrix values='0 0 0 0 0.0785326 0 0 0 0 0 0 0 0 0 0.425 0 0 0 0.05 0'></feColorMatrix>
            <feBlend
              in2='effect1_dropShadow_4257_3909'
              result='effect2_dropShadow_4257_3909'
            ></feBlend>
            <feBlend
              in='SourceGraphic'
              in2='effect2_dropShadow_4257_3909'
              result='shape'
            ></feBlend>
          </filter>
          <linearGradient
            id='paint0_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint1_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint2_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint3_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint4_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint5_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint6_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='101.5'
            y2='231'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint7_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint8_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='113'
            y2='196'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint9_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='37'
            y2='260'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint10_linear_4257_3909'
            x1='240.75'
            x2='240.75'
            y1='119'
            y2='222.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B' stopOpacity='0'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint11_linear_4257_3909'
            x1='112'
            x2='411.486'
            y1='135'
            y2='88.475'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff'></stop>
            <stop offset='1' stopColor='#fff' stopOpacity='0.25'></stop>
          </linearGradient>
          <linearGradient
            id='paint12_linear_4257_3909'
            x1='43'
            x2='26.273'
            y1='-61.5'
            y2='98.881'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.186' stopColor='#FBFF2D'></stop>
            <stop offset='1' stopColor='#F960CE'></stop>
          </linearGradient>
          <linearGradient
            id='paint13_linear_4257_3909'
            x1='168.018'
            x2='168.018'
            y1='101'
            y2='140.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF51F8'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint14_linear_4257_3909'
            x1='168.018'
            x2='168.018'
            y1='97.498'
            y2='124.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <linearGradient
            id='paint15_linear_4257_3909'
            x1='140'
            x2='123.273'
            y1='-61.5'
            y2='98.881'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.186' stopColor='#FBFF2D'></stop>
            <stop offset='1' stopColor='#F960CE'></stop>
          </linearGradient>
          <linearGradient
            id='paint16_linear_4257_3909'
            x1='265.018'
            x2='265.018'
            y1='101'
            y2='140.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF51F8'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint17_linear_4257_3909'
            x1='265.018'
            x2='265.018'
            y1='97.498'
            y2='124.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <linearGradient
            id='paint18_linear_4257_3909'
            x1='237'
            x2='220.273'
            y1='-61.5'
            y2='98.881'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.186' stopColor='#FBFF2D'></stop>
            <stop offset='1' stopColor='#F960CE'></stop>
          </linearGradient>
          <linearGradient
            id='paint19_linear_4257_3909'
            x1='362.018'
            x2='362.018'
            y1='101'
            y2='140.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF51F8'></stop>
            <stop offset='1' stopColor='#FF8B8B'></stop>
          </linearGradient>
          <linearGradient
            id='paint20_linear_4257_3909'
            x1='362.018'
            x2='362.018'
            y1='97.498'
            y2='124.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <linearGradient
            id='paint21_linear_4257_3909'
            x1='0'
            x2='482.276'
            y1='324'
            y2='182.404'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#fff'></stop>
            <stop offset='1' stopColor='#fff' stopOpacity='0.25'></stop>
          </linearGradient>
          <linearGradient
            id='paint22_linear_4257_3909'
            x1='115.5'
            x2='132.999'
            y1='316.5'
            y2='238.955'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D737FF'></stop>
            <stop offset='1' stopColor='#FF67C2'></stop>
          </linearGradient>
          <linearGradient
            id='paint23_linear_4257_3909'
            x1='56.018'
            x2='56.018'
            y1='208'
            y2='247.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B'></stop>
            <stop offset='1' stopColor='#FF51F8'></stop>
          </linearGradient>
          <linearGradient
            id='paint24_linear_4257_3909'
            x1='56.018'
            x2='56.018'
            y1='204.498'
            y2='231.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <linearGradient
            id='paint25_linear_4257_3909'
            x1='219.5'
            x2='236.999'
            y1='316.5'
            y2='238.955'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D737FF'></stop>
            <stop offset='1' stopColor='#FF67C2'></stop>
          </linearGradient>
          <linearGradient
            id='paint26_linear_4257_3909'
            x1='160.018'
            x2='160.018'
            y1='208'
            y2='247.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B'></stop>
            <stop offset='1' stopColor='#FF51F8'></stop>
          </linearGradient>
          <linearGradient
            id='paint27_linear_4257_3909'
            x1='160.018'
            x2='160.018'
            y1='204.498'
            y2='231.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <linearGradient
            id='paint28_linear_4257_3909'
            x1='323.5'
            x2='340.999'
            y1='316.5'
            y2='238.955'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D737FF'></stop>
            <stop offset='1' stopColor='#FF67C2'></stop>
          </linearGradient>
          <linearGradient
            id='paint29_linear_4257_3909'
            x1='264.018'
            x2='264.018'
            y1='208'
            y2='247.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B'></stop>
            <stop offset='1' stopColor='#FF51F8'></stop>
          </linearGradient>
          <linearGradient
            id='paint30_linear_4257_3909'
            x1='264.018'
            x2='264.018'
            y1='204.498'
            y2='231.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <linearGradient
            id='paint31_linear_4257_3909'
            x1='427.5'
            x2='444.999'
            y1='316.5'
            y2='238.955'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D737FF'></stop>
            <stop offset='1' stopColor='#FF67C2'></stop>
          </linearGradient>
          <linearGradient
            id='paint32_linear_4257_3909'
            x1='368.018'
            x2='368.018'
            y1='208'
            y2='247.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B'></stop>
            <stop offset='1' stopColor='#FF51F8'></stop>
          </linearGradient>
          <linearGradient
            id='paint33_linear_4257_3909'
            x1='368.018'
            x2='368.018'
            y1='204.498'
            y2='231.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <linearGradient
            id='paint34_linear_4257_3909'
            x1='531.5'
            x2='548.999'
            y1='316.5'
            y2='238.955'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D737FF'></stop>
            <stop offset='1' stopColor='#FF67C2'></stop>
          </linearGradient>
          <linearGradient
            id='paint35_linear_4257_3909'
            x1='472.018'
            x2='472.018'
            y1='208'
            y2='247.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FF8B8B'></stop>
            <stop offset='1' stopColor='#FF51F8'></stop>
          </linearGradient>
          <linearGradient
            id='paint36_linear_4257_3909'
            x1='472.018'
            x2='472.018'
            y1='204.498'
            y2='231.348'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#C338FF' stopOpacity='0.2'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
            <stop offset='1' stopColor='#7B23DE'></stop>
          </linearGradient>
          <clipPath id='clip0_4257_3909'>
            <path fill='#fff' d='M0 0H538V324H0z'></path>
          </clipPath>
        </defs>
      </svg>
    </View>
  )
}
