import React, { useContext } from 'react'
import { useField, useFormikContext, FormikContextType } from 'formik'

import { Select, styled } from 'tamagui'

// import { Icon } from '../../components/Icon'
import { Icon } from '../Icon'
import { FormFieldContext } from '../../components/Form/shared'

// import './FormSelect.css'

export const StyledSelect = styled(Select, {
  borderWidth: 1,
  borderColor: 'rgb(230, 230, 230)',
  borderRadius: '$2',

  color: '$grey8',
} as const)

export const FormSelect = StyledSelect.styleable<{
  items: Array<{ value: string; text: string }>
}>(({ items, width, ...props }, ref) => {
  // const formContext = useFormikContext()
  const formFieldContext = useContext(FormFieldContext)
  const [field, _meta, helpers] = useField(formFieldContext.name)

  return (
    <StyledSelect
      ref={ref}
      value={field.value}
      onValueChange={(value) => {
        helpers.setValue(value)
      }}
      {...props}
    >
      <StyledTrigger width={width}>
        <Select.Value placeholder='Select' />
      </StyledTrigger>

      <StyledSelectContent>
        <Select.ScrollUpButton />
        <StyledSelectViewport minWidth={width}>
          {items.map((item, index) => {
            return (
              <StyledSelectItem
                key={item.value}
                index={index}
                value={item.value}
              >
                <StyledSelectItemText borderRadius={4} flex={1}>
                  {item.text}
                </StyledSelectItemText>

                <Select.ItemIndicator>
                  <Icon name='checkmark' size='$5' color='$grey9' />
                </Select.ItemIndicator>
              </StyledSelectItem>
            )
          })}
        </StyledSelectViewport>
        <Select.ScrollDownButton />
      </StyledSelectContent>
    </StyledSelect>
  )
})

export const StyledSelectContent = styled(Select.Content, {
  borderWidth: 1,

  borderRadius: '$2',

  borderColor: 'rgb(230, 230, 230)',

  color: '$grey8',
})

export const StyledSelectItem = styled(Select.Item, {
  outlineStyle: 'unset',

  focusStyle: {
    outlineStyle: 'unset',
  },

  hoverStyle: {
    backgroundColor: '$darkBlue9',
    color: '$grey1',
    cursor: 'pointer',
  },
})

export const StyledSelectItemText = styled(Select.ItemText, {
  color: 'inherit',
})

export const StyledTrigger = styled(Select.Trigger, {
  borderWidth: 1,
  borderRadius: '$2',
  color: '$grey8',

  borderColor: 'rgb(230, 230, 230)',
})

export const StyledSelectViewport = styled(Select.Viewport, {
  borderColor: 'rgb(230, 230, 230)',
})
