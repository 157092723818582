import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay'
// import { ActivityIndicator } from 'react-native'

import { lazy as loadable } from '@loadable/component'

import {
  Element,
  Screen,
  Metadata,
  SuspenseLoadingPageTitle,
  wrapSuspenseScreen,
} from '@components'

import { UnauthenticatedHomeScreen } from './UnauthenticatedHomeScreen'

import { HomeScreenQuery } from './__generated__/HomeScreenQuery.graphql'

const AuthenticatedHomeScreen = wrapSuspenseScreen(
  loadable(() => import('./AuthenticatedHomeScreenNew'))
)

const HIGHLIGHTED_GUILD_SLUG =
  process.env.GUILD_ENV === 'production' ? 'gitnation' : 'gitnation'

export const INITIAL_COUNTS = {
  0: 3,
  1: 2,
  2: 3,
  3: 4,
  4: 4,
}

export const HomeScreen = () => {
  // const tokens = useContext(TokensContext)
  // const { accessToken } = useAuthContext()
  // const serverContext = useContext(ServerContext)

  return (
    <HomeScreenWrapper>
      {/* <Suspense
        fallback={
          <>
            {!serverContext && (
              <SuspenseLoadingPageTitle title='Loading... | Guild' />
            )}

            <Screen>
              <ActivityIndicator
                color={tokens.color.darkBlue[9]}
                size='large'
              />
            </Screen>
          </>
        }
      > */}
      <Metadata
        title='Elevate Your Community'
        description='Guild is the platform for communities. Attend an Event, catch a Presentation, and follow a Network!'
      />

      <HomeScreenContent />
      {/* </Suspense> */}
    </HomeScreenWrapper>
  )
}

const HomeScreenContent = () => {
  const data = useLazyLoadQuery<HomeScreenQuery>(
    graphql`
      query HomeScreenQuery($highlightedGuildSlug: String!) {
        viewer {
          id
        }

        ...UnauthenticatedHomeScreen_query
      }
    `,
    {
      highlightedGuildSlug: HIGHLIGHTED_GUILD_SLUG,
    }
  )

  if (data.viewer?.id) {
    return <AuthenticatedHomeScreen />
  }

  return <UnauthenticatedHomeScreen query={data} />
}

const HomeScreenWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Element
      tokens={{
        width: '100%',
        backgroundColor: 'grey.1',
        flexGrow: 1,
      }}
    >
      {children}
    </Element>
  )
}

export default HomeScreen
