import React, { FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { useLocation } from 'react-router-dom'

import { Element } from '../Element'
import { Link } from '../Link'
import { LinkText, Text } from '../Text'
import { Stack } from '../Stack'
import { UserPrimaryPhoto } from '../User/UserPrimaryPhoto'
import { UserDefaultPhoto } from '../User/UserDefaultPhoto'

import { Text as NewText } from '@newComponents'

import {
  PresentationItem_presentation,
  PresentationItem_presentation$key,
} from './__generated__/PresentationItem_presentation.graphql'
import { FormattedMarkdown } from '@newComponents'

export const PresentationItem: FC<{
  presentation: PresentationItem_presentation$key
  openAsModal?: boolean
  numberOfLines?: number
}> = ({ presentation, openAsModal = false, numberOfLines }) => {
  const location = useLocation()
  const data = useFragment(
    graphql`
      fragment PresentationItem_presentation on Presentation {
        rowId
        description
        title
        presenterFirstName
        presenterLastName
        prettyUrl

        presenter {
          slugId
          firstName
          lastName

          ...UserPrimaryPhoto_user
        }
      }
    `,
    presentation
  )

  return (
    <Stack direction='horizontal' gap={4} variant='wrap'>
      <PresentationIcon presentation={data} />

      <Stack expand>
        <Link
          to={`/presentations/${data.prettyUrl}`}
          options={
            openAsModal
              ? { state: { backgroundLocation: location } }
              : undefined
          }
        >
          <Stack gap={2}>
            <Text size={6} variant='heading'>
              {data.title}
            </Text>

            {!!data.description && (
              <FormattedMarkdown
                textSize='$4'
                formattedVariant='PLAIN_TEXT'
                numberOfLines={numberOfLines}
              >
                {data.description}
              </FormattedMarkdown>
            )}
          </Stack>
        </Link>
      </Stack>
    </Stack>
  )
}

const PresentationIcon: FC<{
  presentation: PresentationItem_presentation
}> = ({ presentation }) => {
  if (!presentation.presenter) {
    return (
      <Stack>
        <Element tokens={{ width: 18 }}>
          <Stack alignItems='center' gap={2}>
            <UserDefaultPhoto stableId={presentation.rowId} size={12} />

            <Stack alignItems='center' variant='fullWidth'>
              <NewText size='$4' textAlign='center'>
                {presentation.presenterFirstName}{' '}
                {presentation.presenterLastName}
              </NewText>
            </Stack>
          </Stack>
        </Element>
      </Stack>
    )
  }

  return (
    <Stack>
      <Element tokens={{ width: 18 }}>
        <Link to={`/${presentation?.presenter?.slugId}`}>
          <Stack alignItems='center' gap={2}>
            <UserPrimaryPhoto user={presentation.presenter} />

            <Stack alignItems='center' variant='fullWidth'>
              <NewText size='$4' textAlign='center'>
                {presentation.presenter.firstName}{' '}
                {presentation.presenter.lastName}
              </NewText>
            </Stack>
          </Stack>
        </Link>
      </Element>
    </Stack>
  )
}
