import React, { FC, useMemo, useState, useEffect } from 'react'
import { View } from 'react-native'
import { useFragment, graphql } from 'react-relay'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  interpolate,
  Extrapolate,
  Easing,
  withTiming,
  withSpring,
  withSequence,
} from 'react-native-reanimated'

import { useLocation } from 'react-router-dom'

import {
  Card,
  Stack,
  Text,
  Image,
  Element,
  Icon,
  useBreakpoint,
  Grid,
  Column,
  Link,
} from '@components'
import { selectBreakpointToken } from '@components/util/selectBreakpointToken'

import { HOST_NAME } from '@data/config/url'

import { HomeScreenPresentationsCard_query$key } from './__generated__/HomeScreenPresentationsCard_query.graphql'

const PRESENTATION_CARD_LAYOUT_WIDTH = 392

export const HomeScreenPresentationsCard: FC<{
  query: HomeScreenPresentationsCard_query$key
}> = ({ query }) => {
  const data = useFragment(
    graphql`
      fragment HomeScreenPresentationsCard_query on Query {
        presentationsCard: presentations(
          first: 12
          orderBy: [CREATED_AT_DESC, ID_ASC]
        ) {
          edges {
            node {
              prettyUrl

              presenter {
                id
              }
            }

            cursor
          }
        }
      }
    `,
    query
  )

  // Load 12 Presentations and filter them down by unique Presenters
  const presentations = useMemo(() => {
    if (!data.presentationsCard) {
      return null
    }

    const presentations = data.presentationsCard

    const filteredPresentations = presentations.edges.reduce(
      (memo, edge, index) => {
        if (memo.length >= 4) {
          return memo
        }

        if (edge.node.presenter) {
          const presenter = edge.node.presenter

          const indexOfFirstByPresenter = presentations.edges.findIndex((e) => {
            if (e.node.presenter) {
              return e.node.presenter.id === presenter.id
            }

            return false
          })

          if (indexOfFirstByPresenter !== index) {
            return memo
          }
        }

        return [...memo, edge]
      },
      []
    )

    if (filteredPresentations.length >= 4) {
      return filteredPresentations
    }

    // Fallback to unfiltered data if not enough
    return data.presentationsCard.edges.slice(0, 4)
  }, [data.presentationsCard])

  const imageURLs = useMemo(() => {
    if (!presentations) {
      return ['', '', '', '']
    }

    return presentations.map((edge) => {
      return `${HOST_NAME}/social/presentation/${edge.node.prettyUrl}/card.svg`
    })
  }, [presentations])

  const urls = useMemo(() => {
    if (!presentations) {
      return ['', '', '', '']
    }

    return presentations.map((edge) => {
      return `/presentations/${edge.node.prettyUrl}`
    })
  }, [presentations])

  const breakpoint = useBreakpoint()

  const [cardPosition, setCardPositions] = useState([0, 1, 2, 3])

  const cardWidth = useMemo(() => {
    return selectBreakpointToken(breakpoint, [280, 300, 320, 280])
  }, [breakpoint])

  const cardPositions = useMemo(() => {
    return [
      {
        x: (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2,
        y: 40,
        z: 0,
        blur: 0,
        opacity: 1.0,
        zIndex: 10,
      },
      {
        x:
          (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2 +
          selectBreakpointToken(breakpoint, [
            cardWidth,
            cardWidth * 1.2,
            cardWidth * 1.2,
            cardWidth,
          ]),
        y: selectBreakpointToken(breakpoint, [
          cardWidth * 0.7,
          cardWidth * 0.55,
          cardWidth * 0.5,
          cardWidth * 0.7,
        ]),
        z: -50,
        blur: 0.5,
        opacity: 0.9,
        zIndex: 0,
      },
      {
        x:
          (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2 -
          selectBreakpointToken(breakpoint, [
            cardWidth * 1.2,
            cardWidth * 1.2,
            cardWidth * 1.3,
            cardWidth * 1.1,
          ]),
        y: cardWidth * -0.07,
        z: -100,
        blur: 1,
        opacity: 0.8,
        zIndex: 0,
      },

      {
        x:
          (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2 -
          selectBreakpointToken(breakpoint, [
            cardWidth * 1.2,
            cardWidth * 1.2,
            cardWidth * 1.4,
            cardWidth,
          ]),
        y: selectBreakpointToken(breakpoint, [
          cardWidth,
          cardWidth,
          cardWidth * 0.9,
          cardWidth * 1.1,
        ]),
        z: -150,
        blur: 1.5,
        opacity: 0.7,
        zIndex: 0,
      },
    ]
  }, [breakpoint, cardWidth])

  useEffect(() => {
    const springOptions = { mass: 1, damping: 100, stiffness: 50 }

    const intervalRef = setInterval(() => {
      setCardPositions((cardPositions) => {
        // return [...cardPositions.sort(() => Math.random() - 0.5)]

        return [...cardPositions.slice(1), cardPositions[0]]
      })

      mainCircleOpacity.value = withSpring(0, springOptions)
      circle1Opacity.value = withSpring(0, springOptions)
      circle2Opacity.value = withSpring(0, springOptions)
      circle3Opacity.value = withSpring(0, springOptions)

      mainCircleSize.value = withSpring(32 * 2, springOptions)
      circle1Size.value = withSpring(56 * 2, springOptions)
      circle2Size.value = withSpring(84 * 2, springOptions)
      circle3Size.value = withSpring(120 * 2, springOptions, () => {
        mainCircleSize.value = 0
        circle1Size.value = 0
        circle2Size.value = 0
        circle3Size.value = 0

        mainCircleOpacity.value = withSpring(1, springOptions)
        circle1Size.value = withSpring(32, springOptions)
        circle2Size.value = withSpring(32, springOptions)
        circle3Size.value = withSpring(32, springOptions)
        mainCircleSize.value = withSpring(32, springOptions, () => {
          circle1Size.value = 0
          circle1Opacity.value = withSpring(1, springOptions)
          circle2Size.value = withSpring(56, springOptions)
          circle3Size.value = withSpring(56, springOptions)
          circle1Size.value = withSpring(56, springOptions, () => {
            circle2Size.value = 0
            circle2Opacity.value = withSpring(1, springOptions)
            circle3Size.value = withSpring(84, springOptions)
            circle2Size.value = withSpring(84, springOptions, () => {
              circle3Size.value = 0
              circle3Opacity.value = withSpring(1, springOptions)
              circle3Size.value = withSpring(120, springOptions)
            })
          })
        })
      })
    }, 10000)

    return () => {
      clearInterval(intervalRef)
    }
  }, [setCardPositions])

  const mainCircleSize = useSharedValue(32)
  const circle1Size = useSharedValue(56)
  const circle2Size = useSharedValue(84)
  const circle3Size = useSharedValue(120)

  const mainCircleOpacity = useSharedValue(1)
  const circle1Opacity = useSharedValue(1)
  const circle2Opacity = useSharedValue(1)
  const circle3Opacity = useSharedValue(1)

  const mainCircleStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      background:
        'linear-gradient(358.66deg, rgba(85, 51, 235, 1.0) -0.85%, rgba(110, 51, 235, 1.0) 96.87%)',
      boxShadow: 'inset 0px -2px 3px rgba(0, 0, 0, 0.25)',

      // width: 32,
      // height: 32,
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 20,

      transform: [
        {
          perspective: 100,
        },
        {
          translateX:
            (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2 +
            cardWidth -
            mainCircleSize.value / 2,
        },
        { translateY: 40 - mainCircleSize.value / 2 },
        // { scale: mainCircleSize.value },
      ],

      // width: 32,
      // height: 32,

      width: mainCircleSize.value,
      height: mainCircleSize.value,

      opacity: mainCircleOpacity.value,
    }
  }, [])

  const circle1Style = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      background: `linear-gradient(180deg, rgba(85, 51, 235, ${
        0.7 * 0.35
      }) 0%, rgba(85, 51, 235, ${0.2 * 0.35}) 100%)`,

      borderWidth: 1,
      borderColor: `rgba(27, 19, 64, ${0.2 * 0.35})`,
      borderRadius: '50%',

      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 20,

      transform: [
        {
          perspective: 100,
        },
        {
          translateX:
            (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2 +
            cardWidth -
            circle1Size.value / 2,
        },
        { translateY: 40 - circle1Size.value / 2 },
        // { scale: circle1Size.value },
      ],

      width: circle1Size.value,
      height: circle1Size.value,

      // width: 56,
      // height: 56,

      opacity: circle1Opacity.value,

      // opacity: interpolate(
      //   circle1Size.value,
      //   [32, 56],
      //   [0, 1],
      //   Extrapolate.CLAMP
      // ),
    }
  }, [])

  const circle2Style = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      background: `linear-gradient(180deg, rgba(85, 51, 235, ${
        0.7 * 0.3
      }) 0%, rgba(85, 51, 235, ${0.2 * 0.3}) 100%)`,
      borderWidth: 1,
      borderColor: `rgba(27, 19, 64, ${0.2 * 0.3})`,
      borderRadius: '50%',

      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,

      transform: [
        {
          perspective: 100,
        },
        {
          translateX:
            (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2 +
            cardWidth -
            circle2Size.value / 2,
        },
        { translateY: 40 - circle2Size.value / 2 },
        // { scale: circle2Size.value },
      ],

      width: circle2Size.value,
      height: circle2Size.value,

      // width: 84,
      // height: 84,

      opacity: circle2Opacity.value,

      // opacity: interpolate(
      //   circle2Size.value,
      //   [56, 84],
      //   [0, 1],
      //   Extrapolate.CLAMP
      // ),
    }
  }, [])

  const circle3Style = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      background: `linear-gradient(180deg, rgba(229, 173, 255, ${0.2}) 0%, rgba(229, 173, 255, ${
        0.2 * 0.2
      }) 100%)`,
      borderWidth: 1,
      borderColor: `rgba(27, 19, 64, ${0.2 * 0.2})`,
      borderRadius: '50%',

      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,

      transform: [
        {
          perspective: 100,
        },
        {
          translateX:
            (PRESENTATION_CARD_LAYOUT_WIDTH - cardWidth) / 2 +
            cardWidth -
            circle3Size.value / 2,
        },
        { translateY: 40 - circle3Size.value / 2 },
        // { scale: circle3Size.value },
      ],

      width: circle3Size.value,
      height: circle3Size.value,

      // width: 120,
      // height: 120,

      opacity: circle3Opacity.value,

      // opacity: interpolate(
      //   circle3Size.value,
      //   [84, 120],
      //   [0, 1],
      //   Extrapolate.CLAMP
      // ),
    }
  }, [])

  return (
    <Card variant='homeScreenContent' expand>
      <Stack gap={4}>
        <Stack gap={2}>
          <Text variant='hero' size={7}>
            Presentations
          </Text>

          <Grid>
            <Column span={[1, 1, 5, 1]}>
              <View style={{ zIndex: 20 }}>
                <Text size={4} variant='subHeading'>
                  Missed an Event? Catch the Presentations any time!{' '}
                  <Text size={4} variant='caption'>
                    As a Presenter, your portfolio lives on Guild. Communities
                    can scout you for content, further expanding your reach.
                    It's like having you own personal fan club!
                  </Text>
                </Text>
              </View>
            </Column>

            <Column span={[0, 0, 1, 0]} />
          </Grid>
        </Stack>

        <Stack alignItems='center'>
          <Element
            style={{ height: 200, width: PRESENTATION_CARD_LAYOUT_WIDTH }}
          >
            <Animated.View style={circle3Style} />

            <Animated.View style={circle2Style} />

            <Animated.View style={circle1Style} />

            <Animated.View style={mainCircleStyle} />

            <PresentationCard
              photoURL={imageURLs[0]}
              url={urls[0]}
              cardPosition={cardPositions[cardPosition[0]]}
              cardWidth={cardWidth}
            />

            <PresentationCard
              photoURL={imageURLs[1]}
              url={urls[1]}
              cardPosition={cardPositions[cardPosition[1]]}
              cardWidth={cardWidth}
            />

            <PresentationCard
              photoURL={imageURLs[2]}
              url={urls[2]}
              cardPosition={cardPositions[cardPosition[2]]}
              cardWidth={cardWidth}
            />

            <PresentationCard
              photoURL={imageURLs[3]}
              url={urls[3]}
              cardPosition={cardPositions[cardPosition[3]]}
              cardWidth={cardWidth}
            />
          </Element>
        </Stack>
      </Stack>
    </Card>
  )
}

const PresentationCard: FC<{
  photoURL: string
  url: string
  cardPosition: {
    x: number
    y: number
    z: number
    blur: number
    opacity: number
    zIndex: number
  }
  cardWidth: number
}> = ({ photoURL, url, cardPosition, cardWidth }) => {
  const x = useSharedValue(cardPosition.x)
  const y = useSharedValue(cardPosition.y)
  const z = useSharedValue(cardPosition.z)
  const blur = useSharedValue(cardPosition.blur)
  const opacity = useSharedValue(cardPosition.opacity)
  const zIndex = useSharedValue(cardPosition.zIndex)

  const location = useLocation()

  const animatedStyle = useAnimatedStyle(() => {
    return {
      borderRadius: 16,
      position: 'absolute',

      // shadowColor: 'rgba(10, 37, 64, 0.1)',
      // shadowRadius: 12,

      transform: [
        { perspective: 100 },

        {
          translateX: x.value,
        },

        { translateY: y.value },
        { translateZ: z.value },
      ],
      opacity: opacity.value,
      filter: `blur(${blur.value}px)`,
      zIndex: zIndex.value,
      shadowRadius: interpolate(z.value, [-150, 0], [5, 20]),
      shadowOffset: {
        width: 0,
        height: interpolate(z.value, [-150, 0], [2, 15]),
      },
      shadowColor: `rgba(10, 37, 64, ${interpolate(
        z.value,
        [-150, 0],
        [0.1, 0.4]
      )})`,
    }
  }, [])

  useEffect(() => {
    const springOptions = { mass: 1, damping: 100, stiffness: 100 }

    zIndex.value = cardPosition.zIndex

    x.value = withSpring(cardPosition.x, springOptions)
    y.value = withSpring(cardPosition.y, springOptions)
    z.value = withSpring(cardPosition.z, springOptions)
    blur.value = withSpring(cardPosition.blur, springOptions)
    opacity.value = withSpring(cardPosition.opacity, springOptions)
  }, [cardPosition])

  return (
    <Animated.View style={animatedStyle}>
      <Link to={url} options={{ state: { backgroundLocation: location } }}>
        <Image
          source={{
            uri: photoURL,
          }}
          style={{
            width: cardWidth,
            height: cardWidth / 2,
            borderRadius: 16,
          }}
        />
      </Link>
    </Animated.View>
  )
}
