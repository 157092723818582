import React from 'react'

import ExpoStripeGL, { Gradient } from 'expo-stripe-gl'

import { styled } from 'tamagui'

import { selectBreakpointToken } from '@components/util/selectBreakpointToken'

const GRADIENT_COLOUR_SETS = [
  ['#E18BF3', '#FF88C5', '#FD89B4', '#FFA18F'], // brighter pinkish/purple/orange
  // ['#AF7AF5', '#D078E3', '#E67AD2', '#F580C2'], // darker purple/pink
]

const StyledExpoGL = styled(ExpoStripeGL, {
  width: '100%',
  height: 590,

  borderRadius: '$6',
})

export const HomeScreenGradient = StyledExpoGL.styleable<{
  getGradient?: (gradient: Gradient) => any
}>(({ getGradient, ...props }, ref) => {
  return (
    <StyledExpoGL
      ref={ref}
      {...props}
      config={{
        colors: GRADIENT_COLOUR_SETS[0],
        // activeColors: gradientColours.map(() => true),
        // wireframe: true,
        speed: 0.8,
        // speed: 50,
        // shadowPower: [-1, -1, -1],
        // density: [0.1, 0.1],
        density: [0.03, 0.06],
        // blendDistance: 24,
        scale: [100, 200, 600],
        // scale: [140, 290, 320],
        // darkenTop: false,
      }}
      getGradient={(g) => {
        if (getGradient) {
          getGradient(g)
        }
      }}
    />
  )
})
