/**
 * @generated SignedSource<<e7d9a61facb82ace51510efa9ea5fc85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type GuildTypes = "GUILD" | "NETWORK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UnauthenticatedHomeScreen_query$data = {
  readonly nodeBySlugId: {
    readonly pastEvents?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"EventItemNew_event">;
        };
      }>;
    };
    readonly pastPresentations?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"PresentationItem_presentation">;
        };
      }>;
    };
    readonly upcomingEvents?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"EventItemNew_event">;
        };
      }>;
    };
    readonly upcomingPresentations?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"PresentationItem_presentation">;
        };
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"GuildCardNew_guild">;
  } | null | undefined;
  readonly rankedGuilds: {
    readonly edges: ReadonlyArray<{
      readonly cursor: String;
      readonly node: {
        readonly name: string;
        readonly slugId: string;
        readonly type: GuildTypes | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"GuildCardNew_guild" | "GuildPrimaryPhoto_guild">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EventItemNew_query" | "HomeScreenPresentationsCard_query">;
  readonly " $fragmentType": "UnauthenticatedHomeScreen_query";
};
export type UnauthenticatedHomeScreen_query$key = {
  readonly " $data"?: UnauthenticatedHomeScreen_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnauthenticatedHomeScreen_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "GuildCardNew_guild"
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v2 = {
  "kind": "Literal",
  "name": "timeSelection",
  "value": "UPCOMING"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventItemNew_event"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PresentationsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Presentation",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PresentationItem_presentation"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "highlightedGuildSlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnauthenticatedHomeScreen_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeScreenPresentationsCard_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventItemNew_query"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "highlightedGuildSlug"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodeBySlugId",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": "upcomingEvents",
              "args": [
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": [
                    "START_AT_ASC",
                    "ID_ASC"
                  ]
                },
                (v2/*: any*/)
              ],
              "concreteType": "EventsConnection",
              "kind": "LinkedField",
              "name": "eventsByTimeSelection",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": "eventsByTimeSelection(first:1,orderBy:[\"START_AT_ASC\",\"ID_ASC\"],timeSelection:\"UPCOMING\")"
            },
            {
              "alias": "pastEvents",
              "args": [
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": [
                    "START_AT_DESC",
                    "ID_ASC"
                  ]
                },
                {
                  "kind": "Literal",
                  "name": "timeSelection",
                  "value": "PAST"
                }
              ],
              "concreteType": "EventsConnection",
              "kind": "LinkedField",
              "name": "eventsByTimeSelection",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": "eventsByTimeSelection(first:1,orderBy:[\"START_AT_DESC\",\"ID_ASC\"],timeSelection:\"PAST\")"
            },
            {
              "alias": "upcomingPresentations",
              "args": [
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": [
                    "CREATED_AT_ASC",
                    "ID_ASC"
                  ]
                },
                (v2/*: any*/)
              ],
              "concreteType": "PresentationsConnection",
              "kind": "LinkedField",
              "name": "presentationsByEventTimeSelection",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": "presentationsByEventTimeSelection(first:1,orderBy:[\"CREATED_AT_ASC\",\"ID_ASC\"],timeSelection:\"UPCOMING\")"
            },
            {
              "alias": "pastPresentations",
              "args": [
                (v1/*: any*/),
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": [
                    "CREATED_AT_DESC",
                    "ID_ASC"
                  ]
                },
                {
                  "kind": "Literal",
                  "name": "timeSelection",
                  "value": "OTHER"
                }
              ],
              "concreteType": "PresentationsConnection",
              "kind": "LinkedField",
              "name": "presentationsByEventTimeSelection",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": "presentationsByEventTimeSelection(first:1,orderBy:[\"CREATED_AT_DESC\",\"ID_ASC\"],timeSelection:\"OTHER\")"
            }
          ],
          "type": "Guild",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        },
        {
          "kind": "Literal",
          "name": "guildType",
          "value": "GUILD"
        }
      ],
      "concreteType": "GuildsConnection",
      "kind": "LinkedField",
      "name": "rankedGuilds",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GuildsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Guild",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slugId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GuildPrimaryPhoto_guild"
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "rankedGuilds(first:5,guildType:\"GUILD\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e53a8ee1d717264158dda1514905d07b";

export default node;
