/**
 * @generated SignedSource<<07252826095568d075432fc1719d0a14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeScreenPresentationsCard_query$data = {
  readonly presentationsCard: {
    readonly edges: ReadonlyArray<{
      readonly cursor: String;
      readonly node: {
        readonly presenter: {
          readonly id: string;
        } | null | undefined;
        readonly prettyUrl: string;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "HomeScreenPresentationsCard_query";
};
export type HomeScreenPresentationsCard_query$key = {
  readonly " $data"?: HomeScreenPresentationsCard_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeScreenPresentationsCard_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeScreenPresentationsCard_query",
  "selections": [
    {
      "alias": "presentationsCard",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 12
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "CREATED_AT_DESC",
            "ID_ASC"
          ]
        }
      ],
      "concreteType": "PresentationsConnection",
      "kind": "LinkedField",
      "name": "presentations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PresentationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Presentation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "prettyUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "presenter",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "presentations(first:12,orderBy:[\"CREATED_AT_DESC\",\"ID_ASC\"])"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9a429a0355a61157583f5aae7d155941";

export default node;
