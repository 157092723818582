import React, { FC } from 'react'
import { useFragment, graphql } from 'react-relay'

import { GraphQLImage } from '../GraphQLImage'

import { GuildPrimaryPhoto_guild$key } from './__generated__/GuildPrimaryPhoto_guild.graphql'

export const GuildPrimaryPhoto: FC<{
  guild: GuildPrimaryPhoto_guild$key
  size?: number
  variant?: string
}> = ({ guild, size = 20, variant }) => {
  const data = useFragment(
    graphql`
      fragment GuildPrimaryPhoto_guild on Guild {
        type

        primaryPhoto {
          ...GraphQLImage_image
        }
      }
    `,
    guild
  )

  if (!data.primaryPhoto) {
    return null
  }

  return (
    <>
      <GraphQLImage
        component='GuildCardPrimaryPhoto'
        variant={variant ? variant : size > 10 ? 'large' : 'small'}
        image={data.primaryPhoto}
        maxWidth={size}
        maxHeight={size}
      />
    </>
  )
}
