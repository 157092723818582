/**
 * @generated SignedSource<<13d59beebe90a19fd0beaf65a5185439>>
 * @relayHash 4fa92cf8844ca801906e5184adf88af5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ff6da37dae8d2ce6251483b768e17253cd88cc552ff05c04eed4f92c569e7f95

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeScreenQuery$variables = {
  highlightedGuildSlug: string;
};
export type HomeScreenQuery$data = {
  readonly viewer: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UnauthenticatedHomeScreen_query">;
};
export type HomeScreenQuery = {
  response: HomeScreenQuery$data;
  variables: HomeScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "highlightedGuildSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    "CREATED_AT_DESC",
    "ID_ASC"
  ]
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prettyUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slugId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "GuildMembership",
  "kind": "LinkedField",
  "name": "myMembership",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "membershipLevel",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v14 = [
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "UsersConnection",
  "kind": "LinkedField",
  "name": "networkMembers",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v17 = [
  (v7/*: any*/),
  (v16/*: any*/),
  (v1/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryPhoto",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "backgroundPhoto",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v20 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v21 = {
  "kind": "Literal",
  "name": "timeSelection",
  "value": "UPCOMING"
},
v22 = [
  (v7/*: any*/),
  (v1/*: any*/),
  (v16/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailMd5",
  "storageKey": null
},
v26 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v27 = [
  (v23/*: any*/),
  (v24/*: any*/),
  (v7/*: any*/),
  (v25/*: any*/),
  (v18/*: any*/),
  (v1/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v29 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    "CREATED_AT_ASC",
    "ID_ASC"
  ]
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presenterFirstName",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presenterLastName",
  "storageKey": null
},
v33 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EventsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasExternalUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasVenue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visibility",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "coverPhoto",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          {
            "alias": "eventOwner",
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "primaryPhoto",
                    "plural": false,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v7/*: any*/)
                ],
                "type": "Guild",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isMe",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v25/*: any*/),
                  (v18/*: any*/)
                ],
                "type": "User",
                "abstractKey": null
              },
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "attendeesByRelevance",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "status": {
                    "in": [
                      "ATTENDING_IN_PERSON",
                      "ATTENDING_VIRTUAL",
                      "WAITING_IN_PERSON",
                      "WAITING_VIRTUAL"
                    ]
                  }
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 8
              }
            ],
            "concreteType": "EventAttendeesConnection",
            "kind": "LinkedField",
            "name": "eventAttendeesByRelevance",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventAttendeesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventAttendee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": (v27/*: any*/),
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "eventAttendeesByRelevance(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\",\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}},first:8)"
          },
          {
            "alias": "attendingCount",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "status": {
                    "in": [
                      "ATTENDING_IN_PERSON",
                      "ATTENDING_VIRTUAL"
                    ]
                  }
                }
              }
            ],
            "concreteType": "EventAttendeesConnection",
            "kind": "LinkedField",
            "name": "eventAttendees",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\"]}})"
          },
          {
            "alias": "waitingCount",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "status": {
                    "in": [
                      "WAITING_IN_PERSON",
                      "WAITING_VIRTUAL"
                    ]
                  }
                }
              }
            ],
            "concreteType": "EventAttendeesConnection",
            "kind": "LinkedField",
            "name": "eventAttendees",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EventTicketingTiersConnection",
            "kind": "LinkedField",
            "name": "eventTicketingTiers",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EventAttendee",
            "kind": "LinkedField",
            "name": "myAttendance",
            "plural": false,
            "selections": [
              (v28/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventAttendeeTicketPurchase",
                "kind": "LinkedField",
                "name": "ticketPurchase",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stripePaymentIntentStatus",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  (v12/*: any*/)
                ],
                "type": "Guild",
                "abstractKey": null
              },
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": "eventItemPresentations",
            "args": [
              (v29/*: any*/)
            ],
            "concreteType": "PresentationsConnection",
            "kind": "LinkedField",
            "name": "presentations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PresentationsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Presentation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v30/*: any*/),
                      (v7/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "presenter",
                        "plural": false,
                        "selections": (v27/*: any*/),
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "presentations(orderBy:[\"CREATED_AT_ASC\",\"ID_ASC\"])"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v35 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PresentationsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Presentation",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v10/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "presenter",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v7/*: any*/),
              (v25/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "primaryPhoto",
                "plural": false,
                "selections": [
                  (v34/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UnauthenticatedHomeScreen_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "authorizationLevel",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "presentationsCard",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 12
          },
          (v3/*: any*/)
        ],
        "concreteType": "PresentationsConnection",
        "kind": "LinkedField",
        "name": "presentations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PresentationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Presentation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "presenter",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "presentations(first:12,orderBy:[\"CREATED_AT_DESC\",\"ID_ASC\"])"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "highlightedGuildSlug"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodeBySlugId",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v15/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": "upcomingEvents",
                "args": [
                  (v20/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": [
                      "START_AT_ASC",
                      "ID_ASC"
                    ]
                  },
                  (v21/*: any*/)
                ],
                "concreteType": "EventsConnection",
                "kind": "LinkedField",
                "name": "eventsByTimeSelection",
                "plural": false,
                "selections": (v33/*: any*/),
                "storageKey": "eventsByTimeSelection(first:1,orderBy:[\"START_AT_ASC\",\"ID_ASC\"],timeSelection:\"UPCOMING\")"
              },
              {
                "alias": "pastEvents",
                "args": [
                  (v20/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": [
                      "START_AT_DESC",
                      "ID_ASC"
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "timeSelection",
                    "value": "PAST"
                  }
                ],
                "concreteType": "EventsConnection",
                "kind": "LinkedField",
                "name": "eventsByTimeSelection",
                "plural": false,
                "selections": (v33/*: any*/),
                "storageKey": "eventsByTimeSelection(first:1,orderBy:[\"START_AT_DESC\",\"ID_ASC\"],timeSelection:\"PAST\")"
              },
              {
                "alias": "upcomingPresentations",
                "args": [
                  (v20/*: any*/),
                  (v29/*: any*/),
                  (v21/*: any*/)
                ],
                "concreteType": "PresentationsConnection",
                "kind": "LinkedField",
                "name": "presentationsByEventTimeSelection",
                "plural": false,
                "selections": (v35/*: any*/),
                "storageKey": "presentationsByEventTimeSelection(first:1,orderBy:[\"CREATED_AT_ASC\",\"ID_ASC\"],timeSelection:\"UPCOMING\")"
              },
              {
                "alias": "pastPresentations",
                "args": [
                  (v20/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "timeSelection",
                    "value": "OTHER"
                  }
                ],
                "concreteType": "PresentationsConnection",
                "kind": "LinkedField",
                "name": "presentationsByEventTimeSelection",
                "plural": false,
                "selections": (v35/*: any*/),
                "storageKey": "presentationsByEventTimeSelection(first:1,orderBy:[\"CREATED_AT_DESC\",\"ID_ASC\"],timeSelection:\"OTHER\")"
              }
            ],
            "type": "Guild",
            "abstractKey": null
          },
          (v26/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 5
          },
          {
            "kind": "Literal",
            "name": "guildType",
            "value": "GUILD"
          }
        ],
        "concreteType": "GuildsConnection",
        "kind": "LinkedField",
        "name": "rankedGuilds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GuildsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Guild",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v8/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "primaryPhoto",
                    "plural": false,
                    "selections": [
                      (v34/*: any*/),
                      (v1/*: any*/),
                      (v7/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v15/*: any*/),
                  (v19/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "rankedGuilds(first:5,guildType:\"GUILD\")"
      }
    ]
  },
  "params": {
    "id": "ff6da37dae8d2ce6251483b768e17253cd88cc552ff05c04eed4f92c569e7f95",
    "metadata": {},
    "name": "HomeScreenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "30ef68fcc2d82c70c06a1a0ff0587ff9";

export default node;
