import React, { FC, useMemo, useState, useEffect, useRef } from 'react'
import { View, LayoutRectangle, Pressable } from 'react-native'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  interpolate,
  Extrapolate,
  Easing,
  withTiming,
  withSpring,
  withDelay,
  withRepeat,
  withSequence,
  WithSpringConfig,
  cancelAnimation,
} from 'react-native-reanimated'

import {
  Breakpoint,
  Card,
  Stack,
  Text,
  ImageKitImage,
  Element,
  useBreakpoint,
  Icon,
  Grid,
  Column,
} from '@components'

import { selectBreakpointToken } from '@components/util/selectBreakpointToken'

const HERO_IMAGE =
  'https://ik.imagekit.io/guild/home-page/event-hero_-TmjOalxR.png' as const
const PEOPLE = [
  'https://ik.imagekit.io/guild/home-page/event-person-6_XHMhfs4lG.png',
  'https://ik.imagekit.io/guild/home-page/event-person-5_qykxb9nAi.png',
  'https://ik.imagekit.io/guild/home-page/event-person-4_n3WtuwB26r.png',
  'https://ik.imagekit.io/guild/home-page/event-person-2_1OXItjMTOQ.png',
  'https://ik.imagekit.io/guild/home-page/event-person-3_TcNKkluEC.png',
  'https://ik.imagekit.io/guild/home-page/event-person-1_dPt3WyZZQY.png',
] as const

const AVATARS = [
  'https://ik.imagekit.io/guild/home-page/event-avatar-6_8q97svtYS.png',
  'https://ik.imagekit.io/guild/home-page/event-avatar-5_-YG1DHZjr.png',
  'https://ik.imagekit.io/guild/home-page/event-avatar-4_PP0LZZuy8.png',
  'https://ik.imagekit.io/guild/home-page/event-avatar-2_SNzZvUPQcj.png',
  'https://ik.imagekit.io/guild/home-page/event-avatar-3_lh8edl9M0.png',
  'https://ik.imagekit.io/guild/home-page/event-avatar-1_j_qzJfrC-.png',
] as const
const PEOPLE_BORDER_STYLES = [
  {
    borderColor: 'rgba(139, 68, 230, 0.6)',
    borderWidth: 4,
    boxShadowColor: 'rgba(149, 68, 230, 0.175)',
  },
  {
    borderColor: 'rgba(230, 194, 68, 0.6)',
    borderWidth: 4,
    boxShadowColor: 'rgba(230, 194, 68, 0.175)',
  },
  {
    borderColor: 'rgba(68, 133, 230, 0.6)',
    borderWidth: 4,
    boxShadowColor: 'rgba(68, 162, 230, 0.175)',
  },
  {
    borderColor: 'rgba(68, 133, 230, 0.6)',
    borderWidth: 4,
    boxShadowColor: 'rgba(68, 162, 230, 0.175)',
  },
  {
    borderColor: 'rgba(139, 68, 230, 0.6)',
    borderWidth: 4,
    boxShadowColor: 'rgba(149, 68, 230, 0.175)',
  },
  {
    borderColor: 'rgba(227, 68, 230, 0.6)',
    borderWidth: 4,
    boxShadowColor: 'rgba(227, 68, 230, 0.175)',
  },
] as const

const HERO_WIDTH = [200, 340]
const PEOPLE_WIDTH = 80

export const HomeScreenEventsCard: FC = () => {
  const [cardLayout, setCardLayout] = useState<LayoutRectangle>({
    width: 392,
    height: 200,
    top: 0,
    left: 0,
  })

  const breakpoint = useBreakpoint()
  const eventButtons = useRef()

  const heroWidth = selectBreakpointToken(breakpoint, HERO_WIDTH)

  const [inPersonLayout, setInPersonLayout] = useState<LayoutRectangle>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [onlineLayout, setOnlineLayout] = useState<LayoutRectangle>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [hybridLayout, setHybridLayout] = useState<LayoutRectangle>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  const [selectedButton, setSelectedButton] = useState<
    'in-person' | 'online' | 'hybrid'
  >('in-person')

  const translateX = useSharedValue(0)

  useEffect(() => {
    const springOptions = { mass: 0.2, damping: 8, stiffness: 100 }

    switch (selectedButton) {
      case 'in-person': {
        translateX.value = withSpring(inPersonLayout.x, springOptions)

        break
      }
      case 'online': {
        translateX.value = withSpring(onlineLayout.x, springOptions)

        break
      }
      case 'hybrid': {
        translateX.value = withSpring(hybridLayout.x, springOptions)
      }
    }
  }, [selectedButton, inPersonLayout, onlineLayout, hybridLayout])

  const buttonBackgroundStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      background: 'linear-gradient(358.66deg, #5533EB -0.85%, #6E33EB 96.87%)',
      borderColor: 'rgba(0, 0, 0, 0.15)',
      borderWidth: 1,
      borderRadius: 32,
      width: breakpoint === 0 ? 88 : 100,
      height: 53,
      boxShadow:
        '0px 2px 6px rgba(27, 19, 64, 0.15), inset 0px -2px 3px rgba(0, 0, 0, 0.25)',

      transform: [{ translateX: translateX.value }],
    }
  }, [inPersonLayout.x])

  useEffect(() => {
    if (
      eventButtons.current &&
      typeof window !== 'undefined' &&
      window.IntersectionObserver
    ) {
      let observerConnected = true

      let timeoutId: NodeJS.Timeout | undefined

      const intersectionObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            timeoutId = setTimeout(() => {
              setSelectedButton('online')
            }, 1000)

            intersectionObserver.disconnect()
            observerConnected = false
          }
        },
        { threshold: [1.0] }
      )

      intersectionObserver.observe(eventButtons.current)

      return () => {
        if (observerConnected) {
          intersectionObserver.disconnect()
        }

        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
    }
  }, [])

  return (
    <Card variant='homeScreenContent'>
      <Stack gap={4}>
        <Stack gap={2}>
          <Stack>
            <Text variant='hero' size={7}>
              In-person Events.
            </Text>

            <Text variant='hero' size={7}>
              Online Events. Or Both.
            </Text>
          </Stack>

          <Grid>
            <Column span={[1, 1, 5, 1]}>
              <Text size={4} variant='subHeading'>
                Events are the heartbeat of any community.{' '}
                <Text size={4} variant='caption'>
                  With Guild, you can host In-Person Events with a waitlist
                  based on venue capacity, Online Events without limits, or
                  Hybrid In-Person & Online Events. The possibilities are
                  endless!
                </Text>
              </Text>
            </Column>

            <Column span={[0, 0, 1, 0]} />
          </Grid>
        </Stack>

        <Element
          style={{ height: 200 }}
          onLayout={(event) => setCardLayout(event.nativeEvent.layout)}
        >
          {/* <Animated.View
            style={[
              {
                position: 'absolute',
                left: -24,
                width: 'calc(100% + 48px)',
                height: 224,
                background:
                  'linear-gradient(0deg, #FF6FF1 0%, rgba(255, 111, 241, 0) 100%)',
                opacity: 0.5,
              },
            ]}
          /> */}

          <View
            style={{
              position: 'absolute',
              background:
                'radial-gradient(50% 50% at 50% 50%, #E5ADFF 0%, rgba(229, 173, 255, 0) 100%)',

              width: 420,
              height: 420,
              left: 0,
              top: 0,
            }}
          />

          <View
            style={{
              position: 'absolute',
              background:
                'radial-gradient(50% 50% at 50% 50%, #B4ADFF 0%, rgba(180, 173, 255, 0) 100%)',

              width: 384,
              height: 384,
              right: 0,
              top: 0,
            }}
          />

          <Animated.View
            style={{
              position: 'absolute',
              transform: [
                // { perspective: 100 },
                {
                  translateX: (cardLayout.width - heroWidth) / 2,
                },
                { translateY: 20 },
                // { translateZ: 0 },
              ],
              zIndex: 1,
              //   borderWidth: 4,
              //   borderColor: 'rgba(251, 47, 255, 0.1)',

              borderRadius: 8 * 4,

              //   boxShadow: 'inset 0 0 0 4px rgba(251, 47, 255, 0.5)',
            }}
          >
            {breakpoint === 0 ? (
              <ImageKitImage
                imageUrl={HERO_IMAGE}
                maxWidth={heroWidth / 4}
                maxHeight={190 / 4}
                borderRadius={8}
              />
            ) : (
              <ImageKitImage
                imageUrl={HERO_IMAGE}
                maxWidth={heroWidth / 4}
                borderRadius={8}
              />
            )}

            <View
              style={{
                position: 'absolute',
                bottom: 0,
                padding: 4,
                margin: 8,
                width: 'calc(100% - 16px)',
                borderRadius: 32,
                background:
                  'linear-gradient(rgba(103, 31, 255, 0.6), rgba(250, 130, 238, 0.6))',
                // borderWidth: 4,
                // borderColor: 'rgba(251, 47, 255, 0.1)',
                backdropFilter: 'blur(4px) saturate(250%)',
                WebkitBackdropFilter: 'blur(4px) saturate(250%)',
                boxShadow: '0 0 0 4px rgba(20, 20, 20, 0.2)',
              }}
            >
              <Stack
                // gap={4}
                direction='horizontal'
                justifyContent='space-between'
              >
                <Animated.View
                  style={buttonBackgroundStyle}
                  ref={eventButtons}
                />

                <View
                  onLayout={(event) =>
                    setInPersonLayout(event.nativeEvent.layout)
                  }
                >
                  <Pressable
                    style={{
                      paddingVertical: 8,
                      paddingHorizontal: breakpoint === 0 ? 18 : 24,
                      alignItems: 'center',
                      gap: 4,
                      cursor: 'pointer',
                      width: breakpoint === 0 ? 88 : 100,
                      display: 'flex',
                    }}
                    onPress={() => setSelectedButton('in-person')}
                  >
                    <Icon name='building' variant='captionLight' />

                    <Text variant='captionLight' size={3}>
                      In-Person
                    </Text>
                  </Pressable>
                </View>

                <View
                  onLayout={(event) =>
                    setOnlineLayout(event.nativeEvent.layout)
                  }
                >
                  <Pressable
                    style={{
                      paddingVertical: 8,
                      paddingHorizontal: breakpoint === 0 ? 18 : 24,
                      alignItems: 'center',
                      gap: 4,
                      cursor: 'pointer',
                      width: breakpoint === 0 ? 88 : 100,
                      display: 'flex',
                    }}
                    onPress={() => setSelectedButton('online')}
                  >
                    <Icon name='desktop' variant='captionLight' />

                    <Text variant='captionLight' size={3}>
                      Online
                    </Text>
                  </Pressable>
                </View>

                <Breakpoint display={[1, 2, 3, 4]}>
                  <View
                    onLayout={(event) =>
                      setHybridLayout(event.nativeEvent.layout)
                    }
                  >
                    <Pressable
                      style={{
                        paddingVertical: 8,
                        paddingHorizontal: 24,
                        alignItems: 'center',
                        gap: 4,
                        cursor: 'pointer',
                        width: 100,
                        display: 'flex',
                      }}
                      onPress={() => setSelectedButton('hybrid')}
                    >
                      <Icon name='buildingDesktop' variant='captionLight' />

                      <Text variant='captionLight' size={3}>
                        Hybrid
                      </Text>
                    </Pressable>
                  </View>
                </Breakpoint>
              </Stack>
            </View>
          </Animated.View>

          <AttendeePhoto
            x={(cardLayout.width - heroWidth) / 2 - (PEOPLE_WIDTH * 3) / 4}
            y={30}
            selectedButton={selectedButton}
            photoIndex={0}
          />

          <AttendeePhoto
            x={(cardLayout.width - heroWidth) / 2 - 160}
            y={70}
            scale={0.8}
            selectedButton={selectedButton}
            photoIndex={1}
          />

          <AttendeePhoto
            x={(cardLayout.width - heroWidth) / 2 - 80}
            y={140}
            scale={0.9}
            selectedButton={selectedButton}
            photoIndex={2}
          />

          <AttendeePhoto
            x={(cardLayout.width - heroWidth) / 2 + heroWidth - 10}
            y={40}
            selectedButton={selectedButton}
            photoIndex={3}
          />

          <AttendeePhoto
            x={(cardLayout.width - heroWidth) / 2 + heroWidth + 60}
            y={125}
            scale={0.9}
            selectedButton={selectedButton}
            photoIndex={4}
          />

          <AttendeePhoto
            x={(cardLayout.width - heroWidth) / 2 + heroWidth + 90}
            y={20}
            scale={0.8}
            selectedButton={selectedButton}
            photoIndex={5}
          />
        </Element>
      </Stack>
    </Card>
  )
}

const AttendeePhoto: FC<{
  x: number
  y: number
  scale?: number
  selectedButton: 'in-person' | 'online' | 'hybrid'
  photoIndex: keyof typeof AVATARS &
    keyof typeof PEOPLE &
    keyof typeof PEOPLE_BORDER_STYLES
}> = ({ x, y, scale = 1.0, selectedButton, photoIndex }) => {
  const [photoToUse, setPhotoToUse] = useState(
    selectPhotoToUse(selectedButton, photoIndex)
  )

  const scaleAnimation = useSharedValue(1 * scale)
  const personOpacity = useSharedValue(photoToUse === 'person' ? 1.0 : 0.0)
  const avatarOpacity = useSharedValue(photoToUse === 'avatar' ? 1.0 : 0.0)

  useEffect(() => {
    const springOptions: WithSpringConfig = {
      mass: 1,
      stiffness: 500,
      damping: 30,
    }
    const pauseDuration = 1000

    const newPhotoToUse = selectPhotoToUse(selectedButton, photoIndex)

    if (photoToUse !== newPhotoToUse) {
      if (photoToUse === 'person') {
        personOpacity.value = withSpring(1.0, {
          ...springOptions,
          damping: 100,
          stiffness: 100,
          overshootClamping: true,
        })
        avatarOpacity.value = withSpring(0, {
          ...springOptions,
          damping: 100,
          stiffness: 100,
          overshootClamping: true,
        })
      } else {
        personOpacity.value = withSpring(0, {
          ...springOptions,
          damping: 100,
          stiffness: 100,
          overshootClamping: true,
        })
        avatarOpacity.value = withSpring(1.0, {
          ...springOptions,
          damping: 100,
          stiffness: 100,
          overshootClamping: true,
        })
      }

      scaleAnimation.value = withSpring(1.0 * scale, springOptions)

      scaleAnimation.value = withDelay(
        Math.random() * 100,
        withSequence(
          withSpring(1.1 * scale, springOptions, () => {
            setTimeout(() => {
              if (newPhotoToUse === 'person') {
                personOpacity.value = withSpring(1.0, {
                  ...springOptions,
                  damping: 100,
                  stiffness: 100,
                  overshootClamping: true,
                })
                avatarOpacity.value = withSpring(0, {
                  ...springOptions,
                  damping: 100,
                  stiffness: 100,
                  overshootClamping: true,
                })
              } else {
                personOpacity.value = withSpring(0, {
                  ...springOptions,
                  damping: 100,
                  stiffness: 100,
                  overshootClamping: true,
                })
                avatarOpacity.value = withSpring(1.0, {
                  ...springOptions,
                  damping: 100,
                  stiffness: 100,
                  overshootClamping: true,
                })
              }

              setPhotoToUse(newPhotoToUse)
            }, pauseDuration)
          }),
          withDelay(pauseDuration, withSpring(1.3 * scale, springOptions)),
          withDelay(
            pauseDuration,
            withSpring(1.0 * scale, {
              mass: 1,
              stiffness: 500,
              damping: 10,
            })
          )
        )
      )
    }
  }, [selectedButton, photoToUse, setPhotoToUse, scale])

  const photoStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      // borderWidth: 4,
      // borderColor: 'rgba(230, 68, 200, 0.4)',
      borderRadius: '50%',
      width: PEOPLE_WIDTH * scale + 4,
      height: PEOPLE_WIDTH * scale + 4,
      left: x,
      top: y,

      transform: [
        // { perspective: 100 },
        // {
        //   translateX: x,
        // },
        // { translateY: y },
        // { translateZ: 0 },

        { scale: scaleAnimation.value },
      ],
    }
  }, [x, y])

  const personStyle = useAnimatedStyle(() => {
    const { boxShadowColor, borderColor, borderWidth } =
      PEOPLE_BORDER_STYLES[photoIndex]

    return {
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50%',
      borderColor,
      borderWidth,

      opacity: personOpacity.value,
      boxShadow: `0px 0px 0px ${interpolate(
        scaleAnimation.value,
        [1.15 * scale, 1.3 * scale],
        [4, 12],
        Extrapolate.CLAMP
      )}px ${boxShadowColor}`,
    }
  }, [photoIndex, scale])

  const avatarStyle = useAnimatedStyle(() => {
    const { boxShadowColor, ...rest } = PEOPLE_BORDER_STYLES[photoIndex]

    return {
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50%',
      ...rest,

      opacity: avatarOpacity.value,
      boxShadow: `0px 0px 0px ${interpolate(
        scaleAnimation.value,
        [1.15 * scale, 1.3 * scale],
        [4, 12],
        Extrapolate.CLAMP
      )}px ${boxShadowColor}`,
    }
  }, [photoIndex, scale])

  return (
    <Animated.View
      style={
        // PEOPLE_BORDER_STYLES[photoIndex],
        photoStyle
      }
    >
      <Animated.View style={personStyle}>
        <ImageKitImage
          imageUrl={PEOPLE[photoIndex]}
          maxWidth={(PEOPLE_WIDTH / 4) * scale}
          maxHeight={(PEOPLE_WIDTH / 4) * scale}
          borderRadius='max'
          focus='face'
        />
      </Animated.View>

      <Animated.View style={avatarStyle}>
        <ImageKitImage
          imageUrl={AVATARS[photoIndex]}
          maxWidth={(PEOPLE_WIDTH / 4) * scale}
          maxHeight={(PEOPLE_WIDTH / 4) * scale}
          borderRadius='max'
          focus='face'
        />
      </Animated.View>
    </Animated.View>
  )
}

const selectPhotoToUse = (
  selectedButton: 'in-person' | 'online' | 'hybrid',
  photoIndex: keyof typeof AVATARS | keyof typeof PEOPLE
): 'person' | 'avatar' => {
  if (selectedButton === 'hybrid') {
    if (parseInt(photoIndex, 10) < 3) {
      return 'person'
    }

    // if (Math.random() < 0.5) {
    //   return PEOPLE[photoIndex]
    // }

    return 'avatar'
  }

  if (selectedButton === 'in-person') {
    return 'person'
  }

  return 'avatar'
}
