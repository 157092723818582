import React, { ImgHTMLAttributes } from 'react'

import { styled, ImageProps } from 'tamagui'

export const Image = styled(
  React.forwardRef<
    HTMLImageElement,
    { src: string } & ImgHTMLAttributes<HTMLImageElement>
  >(({ src, ...props }, ref) => {
    return <img {...props} ref={ref} src={src} />
  }),
  { name: 'GuildImage', acceptsClassName: true }
)
