/**
 * @generated SignedSource<<5db3af5fed1d944fbd21e784228204fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PresentationItem_presentation$data = {
  readonly description: string | null | undefined;
  readonly presenter: {
    readonly firstName: string;
    readonly lastName: string;
    readonly slugId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserPrimaryPhoto_user">;
  } | null | undefined;
  readonly presenterFirstName: string | null | undefined;
  readonly presenterLastName: string | null | undefined;
  readonly prettyUrl: string;
  readonly rowId: String;
  readonly title: string;
  readonly " $fragmentType": "PresentationItem_presentation";
};
export type PresentationItem_presentation$key = {
  readonly " $data"?: PresentationItem_presentation$data;
  readonly " $fragmentSpreads": FragmentRefs<"PresentationItem_presentation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PresentationItem_presentation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presenterFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presenterLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prettyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "presenter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slugId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserPrimaryPhoto_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Presentation",
  "abstractKey": null
};

(node as any).hash = "442b11afad420521568cc82993e148bf";

export default node;
