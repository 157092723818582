import { YStack, styled } from 'tamagui'

export const Card = styled(YStack, {
  name: 'Card',
  borderRadius: '$6',
  backgroundColor: '$grey0',
  boxShadow: '$boxShadowLight',

  padding: '$5',
} as const)
