import React, {
  createContext,
  useState,
  useCallback,
  useRef,
  MutableRefObject,
  useContext,
  useMemo,
} from 'react'

export const FormFieldContext = createContext<{
  name: string
  fieldId?: string
  formElementRef: MutableRefObject<any>
  onFocus?: () => void
  onBlur?: () => void
}>(undefined as any)
