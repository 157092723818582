import React, { FC } from 'react'
import { StyleSheet } from 'react-native'

import { Element } from './Element'

export const HorizontalLine: FC<{ variant?: string }> = ({ variant }) => {
  return (
    <Element
      component='HorizontalLine'
      variant={variant}
      style={{
        height: StyleSheet.hairlineWidth,
        borderBottomWidth: StyleSheet.hairlineWidth,
      }}
    />
  )
}
