import React, { FC, useContext, useMemo } from 'react'
import { Element } from './Element'
import { TokensContext, GridContext } from './context'
import { useBreakpoint } from './useBreakpoint'

import { selectBreakpointToken } from './util/selectBreakpointToken'

export const Grid: FC<{ gap?: number }> = ({ children, gap = 8 }) => {
  const tokensContext = useContext(TokensContext)

  const tokenizedGap = useMemo(() => {
    return tokensContext.size[gap]
  }, [gap, tokensContext])

  return (
    <Element style={{ flexDirection: 'row', flex: 1, gap: tokenizedGap }}>
      {children}
    </Element>
  )
}

export const Column: FC<{ span?: number | Array<number> }> = ({
  span = 1,
  children,
}) => {
  const breakpoint = useBreakpoint()

  const breakpointSpan = useMemo(() => {
    return selectBreakpointToken(breakpoint, span)
  }, [breakpoint, span])

  const elementStyles = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: 'column',
      flex: breakpointSpan,
    }
  }, [breakpointSpan])

  return <Element style={elementStyles}>{children}</Element>
}
